<h1 mat-dialog-title>
  <span translate="USER.TITLECREATE"> </span>
</h1>

<div>

  <button mat-raised-button (click)="addUser()" [disabled]="disableAddButton()" class="right-distance bottom-space">
    <mat-icon>add</mat-icon>
  </button>

  <button mat-raised-button (click)="csvInput.click()" [disabled]="disableCsvInputButton()" class="bottom-space">
    {{ 'USER.UPLOADCSV' | translate }}
  </button>

</div>


<div class="table-container bottom-space mat-elevation-z8">

  <table
    mat-table 
    [dataSource]="bulkUserWithStateListTable" 
    matSort 
  >

    <ng-container matColumnDef="saved">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header class="state-header">
        <mat-icon matTooltip="{{globalStatusTooltip}}">{{globalStatusIcon}}</mat-icon>
      </th>
      <td mat-cell *matCellDef="let row" class="user-line">
        <mat-icon matTooltip="{{row.tooltip}}">{{row.icon}}</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="user-header mat-header-email">{{ 'USER.USERNAME' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="user-line mat-cell-email">
        <input matInput (input)="rowChanged(row)" placeholder="{{ 'USER.USERNAME' | translate }}" [value]="row.userName" [(ngModel)]="row.userName" [disabled]="isSubmitting()">
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="user-header">{{ 'USER.FIRSTNAME' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="user-line">
        <input matInput (input)="rowChanged(row)" placeholder="{{ 'USER.FIRSTNAME' | translate }}" [value]="row.firstName" [(ngModel)]="row.firstName" [disabled]="isSubmitting()">
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="user-header">{{ 'USER.LASTNAME' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="user-line">
        <input matInput (input)="rowChanged(row)" placeholder="{{ 'USER.LASTNAME' | translate }}" [value]="row.lastName" [(ngModel)]="row.lastName" [disabled]="isSubmitting()">
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="user-header mat-header-email">{{ 'USER.EMAIL' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="user-line mat-cell-email">
        <input matInput (input)="rowChanged(row)" placeholder="{{ 'USER.EMAIL' | translate }}" [value]="row.email" [(ngModel)]="row.email" [disabled]="isSubmitting()">
      </td>
    </ng-container>

    <ng-container matColumnDef="removeAction">
      <th mat-header-cell *matHeaderCellDef class="user-header">
        <button (click)="removeItem(null)" mat-button><mat-icon>remove</mat-icon></button>
      </th>
      <td mat-cell *matCellDef="let row" class="user-line">
        <button (click)="removeItem(row)" mat-button><mat-icon>remove</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
  </table>

</div>

<div mat-dialog-actions>

  <mat-form-field class="right-distance">
    <mat-label mat-label translate="USER.ROLE"></mat-label>
    <mat-select matInput [(value)]="selectedRole" [disabled]="isSubmitting()">
      <mat-option *ngFor="let selectedRole of roles" [value]="selectedRole">{{ selectedRole }} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="right-distance">
    <mat-label mat-label translate="USER.GROUP"></mat-label>
    <mat-select [(value)]="selectedGroup" [disabled]="isSubmitting()">
      <mat-option *ngFor="let selectedGroup of groups" [value]="selectedGroup">
        <span>
          <span
            *ngFor="
              let dummy of ' '.repeat(selectedGroup.groupIdentifier.split(envService.config.groupIdentifierSeparator).length - 2).split('');
              let x = index
            "
          >
            - </span
          >{{ selectedGroup.groupName }}
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button (click)="onSubmit()" [disabled]="disableCreateButton()" *ngIf="!isSubmitting()">
    {{ 'CREATE' | translate }}
  </button>

  <button mat-raised-button (click)="cancelSubmit()" *ngIf="isSubmitting()">
    {{ 'CANCEL' | translate }}

    <mat-icon><mat-spinner color="accent" diameter="20">
    </mat-spinner></mat-icon>    

  </button>

  <button mat-raised-button [mat-dialog-close] [disabled]="disableCloseButton()">
    {{ 'CLOSE' | translate }}
  </button>

  <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="csvInputChange($event)" accept=".csv"/>

</div>
