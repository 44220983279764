<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <span translate="TRIPS.LIST.TITLE"></span>
    </ng-template>
    <app-trips-list></app-trips-list>
  </mat-tab>
  <mat-tab *ngIf="authService.isAdminOrDispatcher()">
    <ng-template mat-tab-label>
      <span translate="TRIPS.MAP.TITLE"></span>
    </ng-template>
    <ng-template matTabContent>
      <app-trips-map></app-trips-map>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="authService.isAdminOrDispatcher()">
    <ng-template mat-tab-label>
      <span translate="TOOLS.TITLE"></span>
    </ng-template>
    <ng-template matTabContent>
      <app-trips-tools></app-trips-tools>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="authService.isAdmin()">
    <ng-template mat-tab-label>
      <span translate="EVALUATION.TITLE"></span>
    </ng-template>
    <ng-template matTabContent>
      <app-trips-evaluation></app-trips-evaluation>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span translate="DIRECTION.MAP.TITLE"></span>
    </ng-template>
    <ng-template matTabContent>
      <app-direction-map></app-direction-map>
    </ng-template>
  </mat-tab>
  <!-- <mat-tab>
    <ng-template mat-tab-label><span translate="TRIPS.REPORTS.TITLE"></span>
    </ng-template>
    <app-trips-reports></app-trips-reports>
  </mat-tab> -->
</mat-tab-group>
