import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { EnvConfigurationService } from '../service/env-config.service';
import { AuthService } from '../service/auth.service';
import { AccountComponent } from './account/account.component';
import { ResponsiveDesignService } from '../service/responsiveDesign.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public static isDisabled = false;

  public titleKey = 'TITLE';

  public static setDisabled(value: boolean): void {
    NavigationComponent.isDisabled = value;
  }

  public disabled(): boolean {
    return NavigationComponent.isDisabled;
  }

  constructor(
    private translate: TranslateService,
    public envService: EnvConfigurationService,
    public authService: AuthService,
    public dialog: MatDialog,
    public responsiveDesignService: ResponsiveDesignService,
    private dateAdapter: DateAdapter<Date>
  ) {
    // Set language according to value in browsers local storage. If there is no value, use German.
    const language = localStorage.getItem('language');
    translate.setDefaultLang(language ?? 'de');
  }

  ngOnInit(): void {
    if (this.authService.isSensorstationMember()) {
      this.titleKey = 'SENSORSTATION.HEADER';
    }
  }

  public useLanguage(language: string): void {
    localStorage.setItem('language', language);
    this.translate.use(language);
    this.dateAdapter.setLocale(language);
  }

  public openDialog(): void {
    this.dialog.open(AccountComponent);
  }
}
