<app-overlay-spinner *ngIf="networkService.offlineOrLoading"></app-overlay-spinner>
<h1 mat-dialog-title><span translate="VEHICLE.VEHICLETYPE"> </span> {{ form.value.id }}</h1>
<form [formGroup]="form">
  <app-vehicle-type-form [vehicleType]="vehicleType" [groups]="groups"></app-vehicle-type-form>
</form>
<div mat-dialog-actions>
  <button mat-raised-button [disabled]="!isFuelValueOk() || isVehicleTypeUnchanged()" (click)="onSubmit()">
    {{ 'UPDATE' | translate }}
  </button>
  <button mat-raised-button (click)="onClose()">
    {{ 'CLOSE' | translate }}
  </button>
</div>
