<mat-tab-group *ngIf="authService.isSensorstationMember()" >
  <mat-tab>
    <ng-template mat-tab-label>
      <span translate="SENSORSTATION.MAP.TITLE"></span>
    </ng-template>
    <ng-template matTabContent>
      <!--SENSORSTATION MAP-->
      <app-sensorstations-map></app-sensorstations-map>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="authService.isSensorstationAdmin() || authService.isSensorstationEditor()">
    <ng-template mat-tab-label>
      <span translate="SENSORSTATION.LIST.TITLE"></span>
    </ng-template>
    <app-sensorstations-list></app-sensorstations-list>
  </mat-tab>
  <mat-tab>
  <ng-template mat-tab-label>
    <span translate="SENSORSTATION.TRAFFICEMISSION.TITLE"></span>
  </ng-template>
  <ng-template matTabContent>
    <app-sensorstation-estm-dashboard></app-sensorstation-estm-dashboard>
    <!--SENSORSTATION HEATMAP-->
  </ng-template>
</mat-tab>
</mat-tab-group>