import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogAction,
  ConfirmationDialogEntity,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Group, RoleConstants, User } from 'lcmm-lib-js';
import { UserPageRequest, UserService } from 'src/app/service/user.service';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { sortString, getLeveledGroupName } from 'src/app/utils/utils';
import { map, startWith } from 'rxjs/operators';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import {
  NetworkService,
  NetworkStateEvent,
} from 'src/app/service/network.service';
import { UserCreateDialogComponent } from './user-create-dialog/user-create-dialog.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, AfterViewInit, OnDestroy {
  private className = 'UserComponent';

  public unfilteredGroups: Group[];

  public filteredGroups: Observable<Group[]>;

  public groupFilter = new UntypedFormControl();

  public users: User[];

  public roles = [
    'USER.ALL_ROLES',
    RoleConstants.DRIVER,
    RoleConstants.DISPATCHER,
    'USER.NO_ROLES',
  ];

  public selectedGroup: Group;

  public selectedRole: string;

  public userNameFilter: string;

  public firstNameFilter: string;

  public lastNameFilter: string;

  public emailFilter: string;

  public displayedColumns: string[] = [
    'userRole',
    'group',
    'userName',
    'firstName',
    'lastName',
    'email',
    'delete',
  ];

  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];

  public pageSize = 10;

  public usersListTable: MatTableDataSource<User> = new MatTableDataSource();

  private previousPageSize = -1;

  public userPageSize: number;

  public userPage: number;

  @ViewChild(MatSort) public sort: MatSort;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    public envService: EnvConfigurationService,
    private responsiveDesignService: ResponsiveDesignService,
    public networkService: NetworkService
  ) {}

  private resize(pageSize: number): void {
    if (this.pageSize !== pageSize) {
      this.resetPage(pageSize);
      this._finalAction();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  private nsCallback(eventType: NetworkStateEvent, msg?: any): void {
    switch (eventType) {
      case NetworkStateEvent.ONLINE:
        this.queryUsers();
        break;
      case NetworkStateEvent.CLIENTERROR:
      case NetworkStateEvent.SERVERERROR:
        this.paginator.pageIndex -= 1;
        break;
      default:
        break;
    }
  }

  ngAfterViewInit(): void {
    this.networkService.register(this.className, this.nsCallback.bind(this));
    this.queryUsers();
    this.usersListTable.sort = this.sort;
    this.usersListTable.paginator = this.paginator;
    this.resize(
      this.responsiveDesignService.register(
        this.className,
        this.resize.bind(this)
      )
    );
  }

  ngOnDestroy(): void {
    this.networkService.deregister(this.className);
    this.responsiveDesignService.deregister(this.className);
  }

  ngOnInit(): void {
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.unfilteredGroups = groups.sort(sortString('groupIdentifier'));
        this.filteredGroups = this.groupFilter.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
  }

  private filterGroups(value: string): Group[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.unfilteredGroups.filter((group) => {
        if (group.groupName) {
          return group.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.unfilteredGroups;
  }

  public create(): void {
    const dialogRef = this.dialog.open(UserCreateDialogComponent, {
      width: '80%',
      data: { groups: this.unfilteredGroups },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.queryUsers();
    });
  }

  public delete(user: User): void {
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.user,
      action: ConfirmationDialogAction.delete,
      data: user.userName,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.userService.deleteUser(user).then(() => {
          this.queryUsers();
        });
      }
    });
  }

  public resetPage(pageSize: number): void {
    if (this.previousPageSize > 0 && this.previousPageSize !== pageSize) {
      this.paginator.firstPage();
    }
    if (this.paginator) {
      this.paginator.pageSize = pageSize;
    }
    this.pageSize = pageSize;
    this.previousPageSize = pageSize;
  }

  private filterAndSortUsers(users: User[]): User[] {
    let _users: User[] = users;
    if (this.selectedGroup) {
      _users = _users.filter(
        (user) => user.group === this.selectedGroup.groupName
      );
    }
    switch (this.selectedRole) {
      case RoleConstants.DRIVER:
      case RoleConstants.DISPATCHER: {
        _users = _users.filter((user) => user.userRole === this.selectedRole);
        break;
      }
      case 'USER.NO_ROLES': {
        _users = _users.filter(
          (user) => !user.userRole || user.userRole.length <= 0
        );
        break;
      }
      default: {
        _users = _users.filter(
          (user) => user.userRole && user.userRole.length > 0
        );
      }
    }
    return _users.sort(sortString('userName'));
  }

  private _finalAction(): void {
    this.usersListTable.data = this.users;
    this.usersListTable._updateChangeSubscription();
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  public queryUsers(): void {
    const upr: UserPageRequest = this.userService.emptyUserPageRequest();
    upr.username = this.userNameFilter;
    upr.firstname = this.firstNameFilter;
    upr.lastname = this.lastNameFilter;
    upr.email = this.emailFilter;
    this.users = [];
    this.userService.getUsers(upr).subscribe(
      (users) => {
        this.users = this.filterAndSortUsers(users);
        this._finalAction();
      },
      () => {
        this.users = [];
        this._finalAction();
      }
    );
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    this.queryUsers();
  }

  public removeGroup(): void {
    this.selectedGroup = undefined;
    this.groupFilter.reset();
    this.queryUsers();
  }

  public leveledGroupName(group: Group): string {
    return getLeveledGroupName(group);
  }
}
