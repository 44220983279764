import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, empty } from 'rxjs';
import { Vehicle, VehicleType } from 'lcmm-lib-js';
import { map, tap, catchError } from 'rxjs/operators';
import { PageRequest, Page } from '../datasource/page';
import { CustomHttpParamEncoder } from '../utils/custom-http-param-encoder';
import { EnvConfigurationService } from './env-config.service';
import { FuelType } from '../utils/utils';
import { AuthService } from './auth.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  params: {
    size: '999999',
  },
};

export interface VehicleQuery {
  driver: { id: string };
  group: { groupIdentifier: string };
  global: string;
}

export interface VehicleTypeQuery {
  brand: string;
  series: string;
  model: string;
  group: { groupIdentifier: string };
}

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private URL: string;

  private static VEHICLE_FETCH_SIZE = 10000;

  private static VEHICLE_TYPE_FETCH_SIZE = 10000;

  private _vehicles = new BehaviorSubject<Vehicle[]>(null);

  private _vehicleTypes = new BehaviorSubject<VehicleType[]>(null);

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public envService: EnvConfigurationService
  ) {
    this.URL = envService.config.tripManagementUrl;
    this._readAllVehiclesAsync();
    if (authService.isAdminOrDispatcher()) {
      this._readAllVehicleTypesAsync();
    }
  }

  private _readAllVehiclesAsync(page?: Page<Vehicle>, start?: number): void {
    let _startTs = start;
    if (!_startTs) {
      _startTs = Date.now();
    }
    let _page = page;
    if (!_page) {
      _page = {
        content: [],
        continuationNextPage: null,
        number: 0,
        size: VehicleService.VEHICLE_FETCH_SIZE,
        totalElements: 0,
      };
      this._vehicles.next(_page.content);
    }
    const _request: PageRequest = {
      page: _page.number,
      size: _page.size,
      requestContinuation: _page.continuationNextPage,
      sort: { property: '_name', order: 'asc' },
    };
    this.page(_request, { group: null, driver: null, global: null }).subscribe(
      (p) => {
        _page.content = _page.content.concat(p.content);
        this._vehicles.next(_page.content);
        if (p.continuationNextPage) {
          _page.number += 1;
          _page.continuationNextPage = p.continuationNextPage;
          this._readAllVehiclesAsync(_page, _startTs);
        }
      }
    );
  }

  private _readAllVehicleTypesAsync(
    page?: Page<VehicleType>,
    start?: number
  ): void {
    let _startTs = start;
    if (!_startTs) {
      _startTs = Date.now();
    }
    let _page = page;
    if (!_page) {
      _page = {
        content: [],
        continuationNextPage: null,
        number: 0,
        size: VehicleService.VEHICLE_TYPE_FETCH_SIZE,
        totalElements: 0,
      };
      this._vehicleTypes.next(_page.content);
    }
    const _request: PageRequest = {
      page: _page.number,
      size: _page.size,
      requestContinuation: _page.continuationNextPage,
      sort: { property: 'brand', order: 'asc' },
    };
    this.vehicleTypesPage(_request, {
      brand: null,
      group: null,
      model: null,
      series: null,
    }).subscribe((p) => {
      _page.content = _page.content.concat(p.content);
      this._vehicleTypes.next(_page.content);
      if (p.continuationNextPage) {
        _page.number += 1;
        _page.continuationNextPage = p.continuationNextPage;
        this._readAllVehicleTypesAsync(_page, _startTs);
      }
    });
  }

  // public

  public readonly vehicles = this._vehicles.asObservable();

  public readonly vehicleTypes = this._vehicleTypes.asObservable();

  public updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return new Observable((observer) => {
      this.http
        .put<Vehicle>(
          `${this.URL}/groups/${vehicle.groupName}/vehicles/${vehicle.id}`,
          vehicle,
          httpOptions
        )
        .subscribe(
          (editedVehicle) => {
            const vehicles = this._vehicles
              .getValue()
              .map((element) =>
                element.id === editedVehicle.id ? editedVehicle : element
              );
            this._vehicles.next(vehicles);
            observer.next(editedVehicle);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public createVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return new Observable((observer) => {
      this.http
        .post<Vehicle>(
          `${this.URL}/groups/${vehicle.groupName}/vehicles`,
          vehicle,
          httpOptions
        )
        .subscribe(
          (createdVehicle: Vehicle) => {
            this._vehicles.next([...this._vehicles.getValue(), createdVehicle]);
            observer.next(createdVehicle);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public enableVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return new Observable((observer) => {
      this.http
        .post<Vehicle>(
          `${this.URL}/groups/${vehicle.groupName}/vehicles/${vehicle.id}/enable`,
          httpOptions
        )
        .subscribe(
          (editedVehicle) => {
            const vehicles = this._vehicles
              .getValue()
              .map((element) =>
                element.id === editedVehicle.id ? editedVehicle : element
              );
            this._vehicles.next(vehicles);
            observer.next(editedVehicle);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public disableVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return new Observable((observer) => {
      this.http
        .post<Vehicle>(
          `${this.URL}/groups/${vehicle.groupName}/vehicles/${vehicle.id}/disable`,
          httpOptions
        )
        .subscribe(
          (editedVehicle) => {
            const vehicles = this._vehicles
              .getValue()
              .map((element) =>
                element.id === editedVehicle.id ? editedVehicle : element
              );
            this._vehicles.next(vehicles);
            observer.next(editedVehicle);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public deleteVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return new Observable((observer) => {
      this.http
        .delete<Vehicle>(
          `${this.URL}/groups/${vehicle.groupName}/vehicles/${vehicle.id}`,
          httpOptions
        )
        .subscribe(
          () => {
            this._vehicles.next(
              this._vehicles
                .getValue()
                .filter((element) => element.id !== vehicle.id)
            );
            observer.next();
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public updateVehicleType(vehicleType: VehicleType): Observable<VehicleType> {
    return new Observable((observer) => {
      this.http
        .put<VehicleType>(
          `${this.URL}/groups/${vehicleType.groupName}/vehicle-types/${vehicleType.id}`,
          vehicleType,
          httpOptions
        )
        .subscribe(
          (editedVehicleType) => {
            const vehicleTypes = this._vehicleTypes
              .getValue()
              .map((element) =>
                element.id === editedVehicleType.id
                  ? editedVehicleType
                  : element
              );
            this._vehicleTypes.next(vehicleTypes);
            observer.next(editedVehicleType);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public createVehicleType(vehicleType: VehicleType): Observable<VehicleType> {
    return new Observable((observer) => {
      this.http
        .post<VehicleType>(
          `${this.URL}/vehicle-types`,
          vehicleType,
          httpOptions
        )
        .subscribe(
          (createdVehicleType: VehicleType) => {
            this._vehicleTypes.next([
              ...this._vehicleTypes.getValue(),
              createdVehicleType,
            ]);
            observer.next(createdVehicleType);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public deleteVehicleType(vehicleType: VehicleType): Observable<VehicleType> {
    return new Observable((observer) => {
      this.http
        .delete<VehicleType>(
          `${this.URL}/groups/${vehicleType.groupName}/vehicle-types/${vehicleType.id}`,
          httpOptions
        )
        .subscribe(
          () => {
            this._vehicleTypes.next(
              this._vehicleTypes
                .getValue()
                .filter((element) => element.id !== vehicleType.id)
            );
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public page(
    request: PageRequest,
    query: VehicleQuery
  ): Observable<Page<Vehicle>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (request.size) params = params.append('size', request.size.toString());
    if (request.requestContinuation)
      params = params.append(
        'requestContinuation',
        request.requestContinuation
      );

    let sortText: string;
    if (request.sort.property && request.sort.order) {
      sortText = request.sort.property;
      if (sortText === 'group') sortText = 'groupName';
      // Add order, if available
      if (request.sort.order) sortText = `${sortText},${request.sort.order}`;
      params = params.append('sort', sortText);
    }

    if (query.driver) params = params.append('user', query.driver.id);
    if (query.group)
      params = params.append('group', query.group.groupIdentifier);
    if (query.global) params = params.append('global', query.global);

    const options = {
      headers,
      params,
      observe: 'response' as 'body',
    };

    return this.http
      .get<HttpResponse<Vehicle[]>>(`${this.URL}/vehicles`, options)
      .pipe(
        tap((data) => {
          data.body.forEach((element) => {
            if (
              element.lastTimestamp &&
              element.lastTimestamp.toString().startsWith('+')
            ) {
              // eslint-disable-next-line no-param-reassign
              element.lastTimestamp = undefined;
            }

            if (
              element.lastModified &&
              element.lastModified.toString().startsWith('+')
            ) {
              // eslint-disable-next-line no-param-reassign
              element.lastModified = undefined;
            }
          });
        }),
        map((data: HttpResponse<Vehicle[]>) => {
          return <Page<Vehicle>>{
            content: data.body,
            number: request.page, // Aktuelle Seitenzahl
            size: request.size, // Anzahl angezeigter Einträge
            totalElements: Number(data.headers.get('X-Total-Count')),
            continuationNextPage: data.headers.get('x-continuation-token'),
          };
        }),
        catchError(this.handleError)
      );
  }

  public vehicleTypesPage(
    request: PageRequest,
    query: VehicleTypeQuery
  ): Observable<Page<VehicleType>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (request.size) params = params.append('size', request.size.toString());
    if (request.requestContinuation)
      params = params.append(
        'requestContinuation',
        request.requestContinuation
      );

    let sortText: string;
    if (request.sort.property && request.sort.order) {
      sortText = request.sort.property;
      if (sortText === 'group') sortText = 'groupName';
      // Add order, if available
      if (request.sort.order) sortText = `${sortText},${request.sort.order}`;
      params = params.append('sort', sortText);
    }

    if (query.brand) params = params.append('brand', query.brand);
    if (query.series) params = params.append('series', query.series);
    if (query.model) params = params.append('model', query.model);
    if (query.group)
      params = params.append('group', query.group.groupIdentifier);

    const options = {
      headers,
      params,
      observe: 'response' as 'body',
    };

    return this.http
      .get<HttpResponse<VehicleType[]>>(`${this.URL}/vehicle-types`, options)
      .pipe(
        /*
        tap((data) => {
          console.error('##http get vehicle-types options?', options);
          const n = data.body.length;
          let i = 1;
          data.body.forEach((element) => {
            const e = element;
            console.error('##vehicle-types ', i++, '/', n, ' ?', e);
          });
        }),
        */
        map((data: HttpResponse<VehicleType[]>) => {
          return <Page<VehicleType>>{
            content: data.body,
            number: request.page, // Aktuelle Seitenzahl
            size: request.size, // Anzahl angezeigter Einträge
            totalElements: Number(data.headers.get('X-Total-Count')),
            continuationNextPage: data.headers.get('x-continuation-token'),
          };
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
    }
    // Return an observable with a user-facing error message.
    // return throwError('Something bad happened; please try again later.');
    return empty();
  }

  public static checkOptionalVehicleAttributes(vehicle: Vehicle): Vehicle {
    if (vehicle.hsn === undefined) {
      // eslint-disable-next-line no-param-reassign
      vehicle.hsn = '';
    }
    if (vehicle.tsn === undefined) {
      // eslint-disable-next-line no-param-reassign
      vehicle.tsn = '';
    }
    if (vehicle.wltpClass === undefined) {
      // eslint-disable-next-line no-param-reassign
      vehicle.wltpClass = 0;
    }
    return vehicle;
  }

  public static checkOptionalVehicleTypeAttributes(
    vehicleType: VehicleType
  ): VehicleType {
    if (vehicleType.hsn === undefined) {
      // eslint-disable-next-line no-param-reassign
      vehicleType.hsn = '';
    }
    if (vehicleType.tsn === undefined) {
      // eslint-disable-next-line no-param-reassign
      vehicleType.tsn = '';
    }
    if (vehicleType.wltpClass === undefined) {
      // eslint-disable-next-line no-param-reassign
      vehicleType.wltpClass = 0;
    }
    return vehicleType;
  }

  public isElectric(element: VehicleType): boolean {
    return element.fuelValue === FuelType.ELECTRIC;
  }
}
