import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { CustomMatPaginatorIntl } from '../utils/custom-paginator';
import { SafeHtmlPipe } from '../utils/pipes/safe-html.pipe';
import { ConvertDotGroupNamePipe } from '../utils/pipes/convert-dot-group-name.pipe';
import { SpinnerComponent } from '../utilComponents/spinner/spinner.component';
import { OverlaySpinnerComponent } from '../utilComponents/overlay-spinner/overlay-spinner.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    SafeHtmlPipe,
    ConvertDotGroupNamePipe,
    SpinnerComponent,
    OverlaySpinnerComponent,
  ],
  exports: [
    MatTabsModule,
    MatDialogModule,
    HttpClientModule,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    SpinnerComponent,
    OverlaySpinnerComponent,
    SafeHtmlPipe,
    ConvertDotGroupNamePipe,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
