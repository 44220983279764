import { DataSource } from '@angular/cdk/collections';
import {
  Observable,
  Subject,
  BehaviorSubject,
  combineLatest,
  EMPTY,
} from 'rxjs';
import {
  switchMap,
  startWith,
  share,
  map,
  tap,
  catchError,
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Page, Sort, PaginatedEndpoint } from './page';
import { NetworkService } from '../service/network.service';

interface SimpleDataSource<T> extends DataSource<T> {
  connect(): Observable<T[]>;
  disconnect(): void;
}

export class LcmmDataSource<T, Q> implements SimpleDataSource<T> {
  private className = 'LcmmDataSource';

  private pageNumber = new Subject<number>();

  private sort: BehaviorSubject<Sort>;

  private query: BehaviorSubject<Q>;

  private requestContinuation: { [key: number]: string } = {};

  public page$: Observable<Page<T>>;

  private lastFetchPage: number;

  private lastFetchPageSize: number;

  constructor(
    private typeName: string,
    private endpoint: PaginatedEndpoint<T, Q>,
    initialSort: Sort,
    initialQuery: Q,
    private networkService: NetworkService,
    public pageSize = 10
  ) {
    this.lastFetchPageSize = pageSize;
    this.query = new BehaviorSubject<Q>(initialQuery);
    this.sort = new BehaviorSubject<Sort>(initialSort);
    const param$ = combineLatest([this.query, this.sort]);
    this.page$ = param$.pipe(
      // Reset hash map with continuation token when query or sort has changed
      tap(() => {
        this.requestContinuation = [null];
      }),
      switchMap(([query, sort]) => {
        return this.pageNumber.pipe(
          startWith(0),
          switchMap((page) => {
            return this.endpoint(
              {
                page,
                size: this.pageSize,
                sort,
                requestContinuation: this.requestContinuation[page],
              },
              query
            ).pipe(
              catchError(this.handleError.bind(this)),
              tap((data) => {
                this.requestContinuation[data.number + 1] =
                  data.continuationNextPage;
              })
            );
          })
        );
      }),
      share()
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleError(error: HttpErrorResponse) {
    /*
    console.error(
      this.className,
      '<',
      this.typeName,
      '> lastFetchPage?',
      this.lastFetchPage.valueOf(),
      ' ... handleError?',
      error
    );
    */
    return EMPTY;
  }

  public sortBy(sort: Partial<Sort>): void {
    if (this.networkService.online) {
      const lastSort = this.sort.getValue();
      const nextSort = { ...lastSort, ...sort };
      this.sort.next(nextSort);
    }
  }

  public queryBy(query: Partial<Q>): void {
    if (this.networkService.online) {
      const lastQuery = this.query.getValue();
      const nextQuery = { ...lastQuery, ...query };
      this.query.next(nextQuery);
    }
  }

  public fetch(page?: number, pageSize?: number): void {
    if (this.networkService.online) {
      if (page !== undefined) {
        this.lastFetchPage = page;
      }
      if (pageSize !== undefined) {
        this.lastFetchPageSize = pageSize;
      }
      this.pageSize = this.lastFetchPageSize;
      this.pageNumber.next(this.lastFetchPage);
    }
  }

  public connect(): Observable<T[]> {
    return this.page$.pipe(map((page) => page.content));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public disconnect(): void {}
}
