<app-overlay-spinner *ngIf="loading"></app-overlay-spinner>

<mat-card class="card">
  
  <mat-card-header>
    <mat-card-title>{{ 'TOOLS.DIRECTION' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-actions>
    
<div class="filter">
  <div class="dropdown">

    <mat-form-field hideRequiredMarker="false">
      <mat-label>{{ 'VEHICLE.VEHICLE' | translate }}</mat-label>
      <mat-chip-grid #chipList4>
        <mat-chip-row class="trip-list-mat-chip"
          *ngIf="selectedVehicle"
          (removed)="unselectVehicle()">
          {{ selectedVehicle.name }}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip-row>
        <input *ngIf="!selectedVehicle"
          type="text"
          placeholder
          required
          matInput
          [formControl]="myControlVehicle"
          [matAutocomplete]="auto2"
          [matChipInputFor]="chipList4"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #auto2="matAutocomplete"
        (optionSelected)="selectVehicle($event.option.value)"
        [displayWith]="displayVehicle"
      >
        <mat-option *ngFor="let vehicle of filteredVehicles | async" [value]="vehicle">
          {{ vehicle.name }}
          <span *ngIf="vehicle.originalVehicleId && vehicle.lastModified">
            {{ (vehicle.lastModified | date: 'dd.MM.yyyy, HH:mm') || 'n/a' }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field hideRequiredMarker="false" *ngIf="groups !== undefined && groups.length > 0">
      <mat-label>{{ 'TRIP.GROUPNAME' | translate }}</mat-label>
      <mat-chip-grid #chipList5>
        <mat-chip-row class="trip-list-mat-chip"
          *ngIf="selectedGroup"
          (removed)="unselectGroup()">
          {{ selectedGroup.groupName }}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip-row>
        <input *ngIf="!selectedGroup"
          type="text"
          placeholder
          required
          matInput
          [formControl]="myControlGroup"
          [matAutocomplete]="auto3"
          [matChipInputFor]="chipList5"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #auto3="matAutocomplete"
        (optionSelected)="selectGroup($event.option.value)"
        [displayWith]="displayGroup"
      >
        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
          <span>
            <span
              *ngFor="
                let dummy of ' '.repeat(group.groupIdentifier.split(GROUP_IDENTIFIER_SEPARATOR).length - 2).split('');
                let x = index
              "
            >
              - </span
            >{{ group.groupName }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


    <button mat-raised-button color="primary" (click)="createRoutes()" [disabled]="!selectedVehicle || !coordinatesValid()">
      {{ 'DIRECTION.INSPECT' | translate }}
    </button> 
  </div>
</div>
  <div class="coordinates">

    <mat-form-field>
      <mat-label>{{ 'DIRECTION.START_LAT' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="tripDirection.startCoordinate.lat"
        placeholder
        (change)="updateMap()"
        autocomplete="off"
        [disabled]="isBusy()"
      />
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>{{ 'DIRECTION.START_LNG' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="tripDirection.startCoordinate.lon"
        placeholder
        (change)="updateMap()"
        autocomplete="off"
        [disabled]="isBusy()"
      />
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>{{ 'DIRECTION.END_LAT' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="tripDirection.endCoordinate.lat"
        placeholder
        (change)="updateMap()"
        autocomplete="off"
        [disabled]="isBusy()"
      />
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>{{ 'DIRECTION.END_LNG' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="tripDirection.endCoordinate.lon"
        placeholder
        (change)="updateMap()"
        autocomplete="off"
        [disabled]="isBusy()"
      />
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>{{ 'DIRECTION.ALTERNATIVES' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [min]="alternativesMin"
        [max]="alternativesMax"
        [(ngModel)]="alternatives"
        placeholder
        (change)="updateMap()"
        autocomplete="off"
        [disabled]="isBusy()"
      />
    </mat-form-field>
  </div>

  </mat-card-actions>  

</mat-card>

<div class="mapAndHideDiv">
  <div #googleGlobalMap class="myglobalmap"></div>
  <div id="disableMapDiv" class="myglobalmap disableMap"></div>
</div>

