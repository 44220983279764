import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Trip } from 'lcmm-lib-js';
import { Observable, Subscription } from 'rxjs';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import {
  MultiChartService,
  MultiChart,
  ChartYAxisProperties,
} from 'src/app/service/multi-chart.service';
import { TripService } from 'src/app/service/trip.service';

import {
  ChartDataset,
  ChartOptions,
  ChartType,
  ScaleOptions,
  ScaleType,
  Chart,
  Point,
} from 'chart.js';

export type XAxisType = 'TimeChart' | 'DistanceChart';

@Component({ template: '' })
export abstract class AbstractMultiChartComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  // protected

  protected chartId: string;

  protected xScaleType: ScaleType;

  protected xAxisType: XAxisType;

  // public

  public lineChartHeightPx = 400;

  public lineChartData: ChartDataset[];

  public lineChartOptions: ChartOptions;

  public lineChartPlugins;

  public lineChartType: ChartType;

  public lineChartLabels: string[];

  public lineChartLegend: boolean;

  // private

  private multiChart: MultiChart = null;

  private setXScaleType = true;

  private tripSubscription: Subscription = null;

  private _t: Trip = null;

  @Input() public trip: Trip;

  @Input() public tripObservable: Observable<Trip>;

  @Input() public isSectionDialog: boolean;

  constructor(
    private translateService: TranslateService,
    private mcs: MultiChartService,
    private ces: ChartEventService
  ) {
    this.lineChartHeightPx = ces.getAppHeightPx(40);
  }

  protected abstract register(multiChart: MultiChart): void;

  private compute(multiChart: MultiChart): void {
    if (
      !multiChart.computed &&
      this._t &&
      this._t.positions
      // && (!this.trip || this.trip.id === this._t.id)
    ) {
      // eslint-disable-next-line no-param-reassign
      multiChart.computeChartData = false;
      for (let index = 0; index < multiChart.chartDataSets.length; index += 1) {
        // eslint-disable-next-line no-param-reassign
        multiChart.chartDataSets[index].data =
          multiChart.computeChartDataFunctionList[index](this._t) as Point[];
      }
      // eslint-disable-next-line no-param-reassign
      multiChart.computed = true;
    }
  }

  private subscribeTrip(): void {
    if (this.isSectionDialog) {
      this._t = this.trip;
    } else if (this.tripObservable) {
      this.tripSubscription = this.tripObservable.subscribe(async (t) => {
        this._t = t;
      });
    }
  }

  private unsubscribeTrip(): void {
    if (!this.isSectionDialog && this.tripSubscription !== null) {
      this.tripSubscription.unsubscribe();
      this.tripSubscription = null;
    }
  }

  private setMultiChart(mc: MultiChart): void {
    this.multiChart = mc;
    this.lineChartData = this.multiChart.chartDataSets;
    this.lineChartOptions = this.multiChart.chartOptions;
    this.lineChartPlugins = this.multiChart.chartPlugins;
    this.lineChartType = this.multiChart.chartType;
    this.lineChartLabels = this.multiChart.chartLabels;
    this.lineChartLegend = this.multiChart.chartLegend;
  }

  public reset(): void {
    this.subscribeTrip();
    this.setMultiChart(this.mcs.get(this.chartId, this.isSectionDialog));
    if (!this.multiChart.computed) {
      this.setMultiChart(
        this.mcs.get(
          this.chartId,
          this.isSectionDialog,
          this.register.bind(this),
          this.compute.bind(this),
          this.saveChart.bind(this)
        )
      );
    }
  }

  ngOnInit(): void {
    this.reset();
  }

  ngOnChanges(): void {
    this.reset();
  }

  ngOnDestroy(): void {
    this.ces.emit(CallbackEventType.cleared);
    this.multiChart.isViewInitialized = false;
    this.unsubscribeTrip();
    this.trip = undefined;
  }

  ngAfterViewInit(): void {
    this.multiChart.isViewInitialized = true;
    this.restoreChart();
  }

  // private functions

  private restoreChart(): void {
    try {
      const c: Chart = this.multiChart.chart;
      if (this.multiChart.chartXAxe !== null) {
        c.config.options.scales.x = this.multiChart.chartXAxe as ScaleOptions;
      }
      for (let i = 0; i < c.data.datasets.length; i += 1) {
        const yP: ChartYAxisProperties = this.multiChart.yAxisConfig.get(i);
        c.data.datasets[i].hidden = !yP.visibility;
        const chartYAxe = c.options.scales[`y${i}`];
        // chartYAxe.display = yP.visibility;
        chartYAxe.min = yP.min;
        chartYAxe.max = yP.max;

        if ('title' in chartYAxe) {
          chartYAxe.title = chartYAxe.title || {};
          chartYAxe.title.color = yP.visibility ? yP.color : yP.hiddenColor;
        }
        if ('ticks' in chartYAxe) {
          chartYAxe.ticks = chartYAxe.ticks || {};
          chartYAxe.ticks.color = yP.visibility ? yP.color : yP.hiddenColor;
        }
      }
      c.update();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  private createChangeEvent(chartId: string): void {
    const tsp = TripService.createTripSectionParameter(
      `MultiChart.${chartId}.changed`
    );
    const m: MultiChart = this.mcs.get('TimeChart', this.isSectionDialog);
    if (chartId === m.chartId) {
      if (m.chartXAxe.min !== undefined) {
        tsp.time.startTime = new Date(m.chartXAxe.min);
      }
      if (m.chartXAxe.max !== undefined) {
        tsp.time.endTime = new Date(m.chartXAxe.max);
      }
    } else {
      const c: MultiChart = this.mcs.get(chartId, this.isSectionDialog);
      let p: Point;
      let i: number;

      const { min } = c.chartXAxe;
      if (min !== undefined) {
        i = 0;
        while (i < c.chartDataSets[0].data.length) {
          p = c.chartDataSets[0].data[i] as Point;
          i += 1;
          if (p.x >= (min as number)) {
            tsp.time.startTime = new Date(p.x as number);
            i = c.chartDataSets[0].data.length;
          }
        }
      }

      const { max } = c.chartXAxe;
      if (max !== undefined) {
        i = c.chartDataSets[0].data.length;
        while (i > 0) {
          i -= 1;
          p = c.chartDataSets[0].data[i] as Point;
          if (p.x <= (max as number)) {
            tsp.time.endTime = new Date(p.x as number);
            i = 0;
          }
        }
      }
    }
    if (tsp.time.startTime !== undefined || tsp.time.endTime !== undefined) {
      this.ces.emit(CallbackEventType.changed, tsp);
    }
  }

  private saveChart(): void {
    try {
      if (this.multiChart.isViewInitialized) {
        const c: Chart = this.multiChart.chart;
        // eslint-disable-next-line prefer-destructuring
        this.multiChart.chartXAxe = c.config.options.scales.x;
        for (let i = 0; i < c.data.datasets.length; i += 1) {
          const yP: ChartYAxisProperties = this.multiChart.yAxisConfig.get(i);
          yP.visibility = c.isDatasetVisible(i);
          if (yP.visibility) {
            // eslint-disable-next-line dot-notation
            const { min, max } = c['scales'][i];
            yP.min = min;
            yP.max = max;
          }
          const chartYAxe = c.config.options.scales[`y${i}`];
          // chartYAxe.display = yP.visibility;
          chartYAxe.min = yP.min;
          chartYAxe.max = yP.max;
          if ('title' in chartYAxe) {
            chartYAxe.title = chartYAxe.title || {};
            chartYAxe.title.color = yP.visibility ? yP.color : yP.hiddenColor;
          }
          if ('ticks' in chartYAxe) {
            chartYAxe.ticks = chartYAxe.ticks || {};
            chartYAxe.ticks.color = yP.visibility ? yP.color : yP.hiddenColor;
          }
        }
      }
      if (!this.isSectionDialog) {
        this.createChangeEvent(this.chartId);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  // protected functions

  protected registerComputeChartDataFunction(
    multiChart: MultiChart,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computeChartDataFunction: any,
    yLabel?: string,
    borderColor?: string,
    yLabelPosition?: 'left' | 'right',
    hideData?: boolean,
    yAxisIndex?: number
  ): void {
    if (yLabel === undefined || yLabel === null) {
      // eslint-disable-next-line no-param-reassign
      yLabel = 'Y Axis';
    }
    // eslint-disable-next-line no-param-reassign
    yLabel = this.translateService.instant(yLabel);
    if (borderColor === undefined || borderColor === null) {
      // eslint-disable-next-line no-param-reassign
      borderColor = 'black';
    }
    if (yLabelPosition === undefined || yLabelPosition === null) {
      // eslint-disable-next-line no-param-reassign
      yLabelPosition = 'left';
    }
    if (hideData === undefined || hideData === null) {
      // eslint-disable-next-line no-param-reassign
      hideData = true;
    }

    const dsIndex = multiChart.chartDataSets.length;
    const ds = this.mcs.createChartDataSets();
    ds.borderColor = borderColor;
    ds.backgroundColor = borderColor;
    ds.label = yLabel;
    ds.hidden = hideData;
    const axisIndex = yAxisIndex !== undefined ? yAxisIndex : dsIndex;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ds as any).yAxisID = `y${axisIndex}`;

    if (multiChart.yAxisConfig.get(axisIndex) === undefined) {
      const yProps = this.mcs.createYAxisProperties(
        axisIndex,
        borderColor,
        multiChart.hiddenColor,
        !hideData
      );
      multiChart.yAxisConfig.set(axisIndex, yProps);
    }
    // eslint-disable-next-line no-param-reassign
    multiChart.chartDataSets.push(ds);
    // eslint-disable-next-line no-param-reassign
    multiChart.computeChartDataFunctionList.push(computeChartDataFunction);

    // Configure the y-axis with conditional display and color properties
    if (!multiChart.chartOptions.scales[`y${axisIndex}`]) {
      // eslint-disable-next-line no-param-reassign
      multiChart.chartOptions.scales[`y${axisIndex}`] = this.mcs.createYAxis(
        `y${axisIndex}`,
        yLabel,
        hideData ? multiChart.hiddenColor : borderColor,
        yLabelPosition
      );
    }

    // Update the y-axis properties dynamically
    const yAxis = multiChart.chartOptions.scales[`y${axisIndex}`];
    yAxis.display = true;
    yAxis.ticks.color = hideData ? multiChart.hiddenColor : borderColor;

    // Remove any default y-axis if present
    if (multiChart.chartOptions.scales.y !== undefined) {
      // eslint-disable-next-line no-param-reassign
      delete multiChart.chartOptions.scales.y;
    }

    // Set x-axis scale type if required
    if (this.setXScaleType) {
      this.setXScaleType = false;
      const { chartXAxe } = multiChart;

      if (chartXAxe !== null) {
        // eslint-disable-next-line no-param-reassign
        multiChart.chartOptions.scales.x = chartXAxe;
      } else {
        // eslint-disable-next-line no-param-reassign
        multiChart.chartOptions.scales.x = this.mcs.createXAxis(
          this.xScaleType
        );
      }
    }
  }
}
