import { Component, Input, OnInit } from '@angular/core';
import { Sensorstation } from 'sensorstation-lib-js';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstation-diagrams',
  templateUrl: './sensorstation-diagrams.component.html',
  styleUrls: ['./sensorstation-diagrams.component.scss'],
})
export class SensorstationDiagramsComponent implements OnInit {
  public url: string;

  // Flag to track if CSS has already been injected
  @Input() public sensorstation: Sensorstation;

  constructor(public envService: EnvConfigurationService) {}

  ngOnInit(): void {
    this.url = `${this.envService.config.grafanaUrl}d/bduofv7nrsem8f/sensor-detailed-view?orgId=1
    &var-group=${this.sensorstation.group}&var-deviceId=${this.sensorstation.deviceId}&viewMode`;
  }
}
