import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vehicle, User, Group } from 'lcmm-lib-js';
import { FuelType, isEnumValueKnown } from 'src/app/utils/utils';

import { NetworkService } from 'src/app/service/network.service';
import { VehicleService } from '../../../service/vehicle.service';

@Component({
  selector: 'app-vehicle-dialog',
  templateUrl: './vehicle-dialog.component.html',
  styleUrls: ['./vehicle-dialog.component.scss'],
})
export class VehicleDialogComponent implements OnInit {
  private className = 'VehicleDialogComponent';

  public loading = false;

  public form: UntypedFormGroup;

  public vehicle: Vehicle;

  public isVehicleUpdated: boolean;

  public users: User[];

  public groups: Group[];

  public allowEditing: boolean;

  constructor(
    public dialogRef: MatDialogRef<VehicleDialogComponent>,
    private vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      vehicle: Vehicle;
      users: User[];
      groups: Group[];
      allowEditing: boolean;
    },
    public networkService: NetworkService
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;

    this.vehicle = null;
    if (data.vehicle) {
      this.vehicle = data.vehicle;
      if (this.vehicle.wltpClass === null) {
        this.vehicle.wltpClass = 0; // Show as invalid
      }
    }

    this.users = null;
    if (data.users) {
      this.users = data.users.filter(
        (user: User) => user.userRole === 'DRIVER'
      );
    }

    this.groups = null;
    if (data.groups) {
      this.groups = data.groups;
    }

    this.allowEditing = data.allowEditing;
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
    this.isVehicleUpdated = false;
  }

  private _getVehicle(): Vehicle {
    const vehicle: Vehicle = this.form.value.vehicleForm;
    const vehicleUserIdList: string[] = [];
    const vehicleUserList: User[] = vehicle.user as unknown as User[];
    vehicleUserList.forEach((u) => {
      if (u) {
        vehicleUserIdList.push(u.id);
      }
    });
    vehicle.user = vehicleUserIdList;
    vehicle.groupName = this.vehicle.groupName;
    return vehicle;
  }

  public onSubmit(): void {
    const vehicle: Vehicle = this._getVehicle();
    this.isVehicleUpdated = false;
    this.vehicleService.updateVehicle(vehicle).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (updatedVehicle) => {
        this.isVehicleUpdated = true;
      }
    );
  }

  public onClose(): void {
    this.dialogRef.close(this.isVehicleUpdated);
  }

  public isFuelValueOk(): boolean {
    return isEnumValueKnown(FuelType, this.form.value, 'vehicleForm.fuelValue');
  }
}
