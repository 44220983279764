<app-header></app-header>

<mat-card appearance="outlined" class="mat-card">
  <mat-card-title class="title" translate="PRIVACY.TITLE"></mat-card-title>
</mat-card>

<mat-card appearance="outlined" class="mat-card">
  <mat-card-content class="card-content" [innerHTML]="content"></mat-card-content>
</mat-card>

<mat-card-actions *ngIf="authService.isLoggedInPage()">
  <button mat-button id="revokeButton" (click)="authService.revokeGdpr()">{{ 'PRIVACY.REVOKE' | translate }}</button>
</mat-card-actions>

<app-footer></app-footer>
