<div class="grid-container">
<mat-grid-list [cols]="breakpoint" rowHeight="50px">
    <mat-grid-tile *ngFor="let detail of detailsListSensorstation" class="gridTile">
      <div class="info_element">
        <div class="label" translate="{{ detail.label }}"></div>
        <div class="data">
          <span *ngIf="detail.value != null">{{ detail.value }}</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>