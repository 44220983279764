import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstations-map',
  templateUrl: './sensorstations-map.component.html',
  styleUrls: ['./sensorstations-map.component.scss'],
  providers: [DatePipe],
})
export class SensorstationsMapComponent {
  public url: string;

  constructor(public envService: EnvConfigurationService) {
    this.url = `${envService.config.grafanaUrl}d/cdupg0mlv5s00c/karte?orgId=1&kiosk`;
  }

  // Method to change the URL dynamically
  public changeUrl(newUrl: string) {
    this.url = newUrl;
  }
}
