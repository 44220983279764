<div [style.height.px]="lineChartHeightPx">
  <canvas 

    baseChart
    [datasets]="lineChartData"
    [options]="lineChartOptions"
    [type]="lineChartType"
    [plugins]="lineChartPlugins"
    [labels]="lineChartLabels"
    [legend]="lineChartLegend">
  </canvas>
</div>
