<app-overlay-spinner *ngIf="showSpinner"></app-overlay-spinner>
<div #googleMap [ngStyle]="{'height' : mapHeight}" *ngIf="!isSectionDialog"></div>
<div #googleSectionMap [ngStyle]="{'height' : mapHeight}" *ngIf="isSectionDialog"></div>
<div *ngIf="trip && trip.calculation">
  <mat-dialog-actions class="sectionbuttons">
    <div>
      {{ 'TRIP.DISTANCE' | translate }}: 
      {{ trip.calculation.distance | number: '0.0-2' }} km 
      {{ 'TRIP.DURATION' | translate }}: 
      {{ trip.calculation.duration * 1000 | date:'H:mm:ss':'UTC' }} 
      {{ 'TRIP.POSITIONCOUNT' | translate }}: 
      {{ trip.calculation.positionCount }}   
    </div>
  </mat-dialog-actions>
</div>

