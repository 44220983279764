<app-overlay-spinner *ngIf="networkService.offlineOrLoading || !groups"></app-overlay-spinner>
<div class="filter">

  <mat-form-field>
    <mat-label>{{ 'SENSORSTATION.NAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="deviceNameFilter"/>
    <button mat-button *ngIf="deviceNameFilter" matSuffix mat-icon-button aria-label="Clear" (click)="deviceNameFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'SENSORSTATION.ID' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="deviceIdFilter"/>
    <button mat-button *ngIf="deviceIdFilter" matSuffix mat-icon-button aria-label="Clear" (click)="deviceIdFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field> 
    <mat-label>{{ 'SENSORSTATION.GROUPNAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="groupFilter"/>
    <button mat-button *ngIf="groupFilter" matSuffix mat-icon-button aria-label="Clear" (click)="groupFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<button mat-raised-button (click)="create()">
  {{ 'SENSORSTATION.TITLECREATE' | translate }}
</button>

<table
  mat-table
  matSort
  [dataSource]="sensorstationData"
  class="mat-elevation-z8"
  multiTemplateDataRows="true"
  matSortActive="deviceId"
  matSortDirection="desc"
  matSortStart="desc"
>
<ng-container matColumnDef="sensorstationName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'SENSORSTATION.NAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span>
        {{ row.name }} 
      </span>
    </td>
  </ng-container>
   <ng-container matColumnDef="sensorstationId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'SENSORSTATION.ID' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.deviceId">
        {{ row.deviceId }} 
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="sensorstationGroup">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'SENSORSTATION.GROUPNAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.group">{{ row.group}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="streetName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'SENSORSTATION.STREET' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.street">
        {{ row.street }} 
        <span *ngIf="row.houseNumber"> {{ row.houseNumber }} </span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="cityName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'SENSORSTATION.CITY' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.city">{{ row.city }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'DETAILS' | translate }}
    </th>
    <td mat-cell *matCellDef="let row; let i = dataIndex" class="details">
      <div class="editButtons" *ngIf="isOwner(row) || authService.isSensorstationAdmin()">
        <button mat-raised-button (click)="openEdit(row, true, $event)">
          {{ 'EDIT' | translate }}
        </button>
        <button mat-raised-button (click)="deleteSensorstation(row, $event)">
          {{ 'DELETE' | translate }}
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showDetails(row)"></tr>

</table>

<!-- mat-paginator
  *ngIf="networkService.offlineOrLoading && sensorstationData.page$ | async as page"
  [length]="page.totalElements"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="page.size"
  [pageIndex]="page.number"
  (page)="sensorstationData.fetch($event.pageIndex, $event.pageSize);resetPage($event.pageSize)"
>
</mat-paginator -->
