import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TripResponse } from 'lcmm-lib-js';
import { ChartEventService } from 'src/app/service/chart-event.service';
import { MapHelper } from 'src/app/utils/markers/map-helper';

@Component({
  selector: 'app-trip-routing',
  templateUrl: './trip-routing.component.html',
  styleUrls: ['./trip-routing.component.scss'],
})
export class TripRoutingComponent implements AfterViewInit {
  private static divIdMap = 'googleRoutingMap';

  @ViewChild(TripRoutingComponent.divIdMap, { static: false })
  public gmap: ElementRef;

  public mapHeight: string;

  public mapHelper: MapHelper;

  public tripResponses: TripResponse[] = [];

  public errorMsg: string;

  public title: string;

  public routingMode: boolean;

  public hideSumAndAverage: boolean;

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private ces: ChartEventService,
    private ts: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tripResponses: TripResponse[];
      title: string;
      routingMode: boolean;
      hideSumAndAverage: boolean;
    }
  ) {
    this.errorMsg = null;
    this.mapHelper = new MapHelper(this.ts, this.datePipe);
    this.setMapHeight();
    this.tripResponses = [];
    if (data.tripResponses !== undefined) {
      this.tripResponses = data.tripResponses;
    }
    this.title = data.title;
    this.routingMode = data.routingMode;
    this.hideSumAndAverage = data.hideSumAndAverage;
  }

  ngAfterViewInit(): void {
    this.mapHelper.initMap(this.gmap, false);
    this.mapHelper.addTripResponsesToMap(this.tripResponses);
  }

  private setMapHeight(): void {
    const height = this.ces.getAppHeightPx(10);
    this.mapHeight = `${height}px`;
  }
}
