import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ConfirmationDialogEntity {
  group = 'ENTITY_GROUP',
  user = 'ENTITY_USER',
  model = 'ENTITY_MODEL',
  vehicle = 'ENTITY_VEHICLE',
  sensorstation = 'ENTITY_SENSORSTATION',
}
export enum ConfirmationDialogAction {
  unknown = 'UNKNOWN',
  delete = 'DELETE',
  deleteException = 'DELETEEXCEPTION',
  deactivate = 'DISABLE',
  recalculate = 'TOOLS.NEW_CALC',
  downloadKml = 'TRIP.KMLDOWNLOAD',
  downloadCsv = 'TRIP.CSVDOWNLOAD',
  computeAverageValues = 'TRIP.AVERAGEVALUES',
  compare = 'TRIP.COMPARE',
}
export type ConfirmationDialogData = {
  entity: ConfirmationDialogEntity;
  action: ConfirmationDialogAction;
  data: string;
  data2?: string;
};

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public showActionButton = false;

  public title: string;

  public entity: string;

  public action: string;

  public question1: string;

  public question2: string;

  public value: string;

  constructor(
    dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    this.showActionButton =
      data.action !== ConfirmationDialogAction.deleteException;
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;
    this.entity = data.entity;
    this.action = data.action;
    if (this.action === undefined) {
      this.action = ConfirmationDialogAction.delete;
    }
    this.title = `${this.action}_CAPITAL`;
    this.question1 = `${this.action}_QUESTION_1`;
    this.question2 = `${this.action}_QUESTION_2`;
    this.value = data.data;
  }
}
