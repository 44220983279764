import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CalculatedPosition } from 'lcmm-lib-js';
import { ChartEventService } from 'src/app/service/chart-event.service';
import { Point } from 'chart.js';
import { AbstractChartComponent } from '../abstract-chart/abstract-chart.component';

@Component({
  selector: 'app-trip-speed-profile',
  templateUrl: '../abstract-chart/abstract-chart.component.html',
  styleUrls: ['../abstract-chart/abstract-chart.component.scss'],
})
export class TripSpeedProfileComponent extends AbstractChartComponent {
  constructor(
    translateService: TranslateService,
    dialog: MatDialog,
    ces: ChartEventService
  ) {
    super(translateService, dialog, ces);
    this.yLabel = 'TRIPS.SPEED.YLABEL';
    this.chartType = 'time';
  }

  // eslint-disable-next-line class-methods-use-this
  public getData(positions: CalculatedPosition[]): Point[] {
    const chartPoints: Point[] = [];

    positions.forEach((p) => {
      chartPoints.push({
        x: p.dtime,
        y: Math.round(p.speed * 36) / 10,
      });
    });
    return chartPoints;
  }
}
