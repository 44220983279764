import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import {
  Group,
  User,
  Trip,
  Vehicle,
  RoleConstants,
  TripResponse,
  RecalculatedTrip,
  VehicleType,
} from 'lcmm-lib-js';

import { TripService, TripQuery } from 'src/app/service/trip.service';
import { UserService } from 'src/app/service/user.service';
import { VehicleService } from 'src/app/service/vehicle.service';
import { UntypedFormControl } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { isUserInGroup, sortString } from 'src/app/utils/utils';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/service/auth.service';
import { DateInputErrorStateMatcher } from 'src/app/utils/error-state-matcher';
import { SelectionModel } from '@angular/cdk/collections';
import { NavigationComponent } from 'src/app/navigation/navigation.component';
import { FooterComponent } from 'src/app/footer/footer.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { Download, DownloadType } from 'src/app/service/download.service';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import {
  NetworkService,
  NetworkStateEvent,
} from 'src/app/service/network.service';
import { LcmmDataSource } from '../../datasource/lcmm-data-source';
import { Sort } from '../../datasource/page';
import { TripMultipleDetailsComponent } from './trip-multiple-details/trip-multiple-details.component';
import { TripAnalysisDialogComponent } from './trip-analysis-dialog/trip-analysis-dialog.component';

interface SortButtons {
  sortDirection: string;
  buttonText: string;
  sortQuery:
    | keyof Trip
    | keyof Trip['calculation']
    | keyof Trip['absoluteCalculation'];
}

type ActionFunction = (trip?: Trip) => Observable<unknown>;

type ActionCallback = (cd: ComputingTripResponses) => boolean;

export interface ComputingTripResponses {
  actionType: ConfirmationDialogAction;
  actionFunction: ActionFunction;
  actionCallback: ActionCallback;
  tripResponses: TripResponse[];
  index: number;
  cancel: boolean;
  isCanceld: boolean;
  isComputing: boolean;
  isRunning: boolean;
  isReady: boolean;
  isConfirmed: boolean;
  toCompute: number;
  computedOk: number;
  computedError: number;
  deselect: boolean;
  fetch: boolean;
  runOnce: boolean;
  noResult: boolean;
  runWithoutConfirmation: boolean;
}

@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['./trips-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition('* <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripsListComponent implements OnInit, OnDestroy, AfterViewInit {
  private className = 'TripsListComponent';

  private compareCountMin = 2;

  private compareCountMax = 5;

  // Link dataSource to MatTable
  private initialSort: Sort = { property: 'startTime', order: 'desc' };

  private rows: TripResponse[] = [];

  public selection = new SelectionModel<TripResponse>(true, []);

  public GROUP_IDENTIFIER_SEPARATOR: string;

  public displayedColumns: string[] = [
    'select',
    'groupName',
    'vehicleName',
    'distance',
    'rank',
    'startTime',
    'endTime',
  ];

  public vehicles: Vehicle[];

  public vehicle: Vehicle;

  public group: Group;

  public user: User;

  public users: User[];

  public groups: Group[];

  public length = 0;

  public pageSizeOptions: number[] = [5, 10, 25, 100];

  public maxDate: Date = new Date();

  public sortButtons: SortButtons[] = [
    {
      sortDirection: '',
      buttonText: 'TRIP.FUELCONSUMPTION',
      sortQuery: 'fuelConsumption',
    },
    {
      sortDirection: '',
      buttonText: 'TRIP.AVERAGESPEED',
      sortQuery: 'averageSpeed',
    },
    { sortDirection: '', buttonText: 'TRIP.DURATION', sortQuery: 'duration' },
    { sortDirection: '', buttonText: 'TRIP.TIMELOST', sortQuery: 'timeLost' },
    { sortDirection: '', buttonText: 'TRIP.ECOINDEX1', sortQuery: 'ecoIndex1' },
    { sortDirection: '', buttonText: 'TRIP.ECOINDEX4', sortQuery: 'ecoIndex4' },
    {
      sortDirection: '',
      buttonText: 'TRIP.BRAKINGINDEX',
      sortQuery: 'brakingIndex',
    },
  ];

  public myControlUser = new UntypedFormControl();

  public myControlVehicle = new UntypedFormControl();

  public myControlGroup = new UntypedFormControl();

  public myControlStartDate = new UntypedFormControl(null);

  public myControlEndDate = new UntypedFormControl(null);

  public filteredUsers: Observable<User[]>;

  public filteredVehicles: Observable<Vehicle[]>;

  public filteredGroups: Observable<Group[]>;

  public selectedGroup?: Group;

  public selectedUser?: User;

  public selectedVehicle?: Vehicle;

  public tripsData: LcmmDataSource<TripResponse, TripQuery>;

  public tableSortDirection: SortDirection = 'desc';

  public startDateValid = true;

  public endDateValid = true;

  public errorStateMatcher = new DateInputErrorStateMatcher();

  public actionTypes = ConfirmationDialogAction;

  public ctr: ComputingTripResponses;

  private currentlyDisplayedTrip: TripResponse = null;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  @ViewChild(MatSort) public sort: MatSort;

  @ViewChild('headercheckbox') private headerMatCheckBox: MatCheckbox;

  constructor(
    public dialog: MatDialog,
    private tripService: TripService,
    public vehicleService: VehicleService,
    private userService: UserService,
    public envService: EnvConfigurationService,
    private translate: TranslateService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private responsiveDesignService: ResponsiveDesignService,
    ces: ChartEventService,
    public networkService: NetworkService
  ) {
    this.tripsData = new LcmmDataSource<TripResponse, TripQuery>(
      'Trip',
      (request, query) => this.tripService.page(request, query),
      this.initialSort,
      {
        driver: undefined,
        vehicle: undefined,
        group: undefined,
        startTime: undefined,
        endTime: undefined,
      },
      this.networkService
    );
    this.GROUP_IDENTIFIER_SEPARATOR =
      envService.config.groupIdentifierSeparator;
    this.ctr = TripsListComponent.getComputingTripResponses();
    ces.register(
      this.className,
      CallbackEventType.tripEnd,
      this.tripEnd.bind(this)
    );
    ces.register(
      this.className,
      CallbackEventType.tripChanged,
      this.tripChanged.bind(this)
    );
  }

  private tripEnd(trip: TripResponse): void {
    const cdt = this.currentlyDisplayedTrip;
    if (cdt && !cdt.endTime && cdt.id === trip.id) {
      cdt.endTime = trip.endTime;
      cdt.calculation = trip.calculation;
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private tripChanged(tripResponse: TripResponse): void {
    this.queryTrips();
  }

  ngAfterViewInit(): void {
    this.resizePage(
      this.responsiveDesignService.register(
        this.className,
        this.resizePage.bind(this)
      )
    );
  }

  ngOnDestroy(): void {
    this.networkService.deregister(this.className);
    NavigationComponent.setDisabled(false);
    FooterComponent.setDisabled(false);
    this.responsiveDesignService.deregister(this.className);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  private nsCallback(eventType: NetworkStateEvent, msg?: any): void {
    /*
    console.error(
      '##TripsListComponent.nsCallback eventType?',
      NetworkStateEvent[eventType],
      ' online?',
      this.networkService.online,
      ' loading?',
      this.networkService.loading,
      ' offlineOrLoading?',
      this.networkService.offlineOrLoading
    );
    */
    switch (eventType) {
      case NetworkStateEvent.ONLINE:
        this.tripsData.fetch();
        break;
      case NetworkStateEvent.CLIENTERROR:
      case NetworkStateEvent.SERVERERROR:
        this.paginator.pageIndex -= 1;
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.networkService.register(this.className, this.nsCallback.bind(this));
    this.vehicleService.vehicles.subscribe((vehicles) => {
      if (vehicles) {
        this.vehicles = vehicles.sort(sortString('name'));
        this.filteredVehicles = merge(
          this.myControlVehicle.valueChanges,
          this.myControlUser.valueChanges,
          this.myControlGroup.valueChanges
        ).pipe(
          startWith(''),
          map(() =>
            this.filterVehicles(
              this.myControlVehicle.value,
              this.myControlUser.value,
              this.myControlGroup.value
            )
          )
        );
      }
    });
    this.tripsData.connect().subscribe((tr) => {
      this.rows = tr;
    });
    this.userService.users.subscribe((users) => {
      if (users) {
        this.users = users
          .filter((user) => user.userRole === RoleConstants.DRIVER)
          .sort(sortString('userName'));
        this.filteredUsers = merge(
          this.myControlUser.valueChanges,
          this.myControlGroup.valueChanges
        ).pipe(
          startWith(''),
          map(() =>
            this.filterUsers(
              this.myControlUser.value,
              this.myControlGroup.value
            )
          )
        );
      }
    });
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        this.filteredGroups = this.myControlGroup.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
    this.myControlStartDate.statusChanges.subscribe((status) => {
      this.startDateValid = status === 'VALID';
    });
    this.myControlEndDate.statusChanges.subscribe((status) => {
      this.endDateValid = status === 'VALID';
    });
  }

  private static getComputingTripResponses(
    actionType?: ConfirmationDialogAction,
    actionFunction?: ActionFunction,
    deselect?: boolean,
    fetch?: boolean
  ): ComputingTripResponses {
    const cd: ComputingTripResponses = {
      tripResponses: [],
      actionType: ConfirmationDialogAction.unknown,
      actionFunction: null,
      actionCallback: null,
      index: 0,
      cancel: false,
      isCanceld: false,
      isRunning: false,
      isComputing: false,
      isReady: false,
      isConfirmed: false,
      toCompute: 0,
      computedOk: 0,
      computedError: 0,
      deselect: false,
      fetch: false,
      runOnce: false,
      noResult: false,
      runWithoutConfirmation: false,
    };
    if (actionType !== undefined) {
      cd.actionType = actionType;
    }
    if (actionFunction !== undefined) {
      cd.actionFunction = actionFunction;
    }
    if (deselect !== undefined) {
      cd.deselect = deselect;
    }
    if (fetch !== undefined) {
      cd.fetch = fetch;
    }
    return cd;
  }

  private filterUsers(userName: string, group: Group): User[] {
    if (typeof userName === 'string' || (group && typeof group !== 'string')) {
      return this.users.filter((option) => {
        if (group && typeof group !== 'string') {
          if (!isUserInGroup(option, group)) {
            return false;
          }
        }
        if (userName && typeof userName === 'string' && option.userName) {
          return option.userName.toLowerCase().includes(userName.toLowerCase());
        }
        return true;
      });
    }
    return this.users;
  }

  private filterVehicles(
    vehicle: Vehicle,
    user: User,
    group: Group
  ): Vehicle[] {
    let filteredVehicles = this.vehicles;
    if (
      (user && typeof user !== 'string') ||
      (group && typeof group !== 'string') ||
      vehicle
    ) {
      filteredVehicles = filteredVehicles.filter((option) => {
        if (group && option.groupName !== group.groupIdentifier) {
          return false;
        }
        if (user && (!option.user || !option.user.includes(user.id))) {
          return false;
        }
        if (
          vehicle &&
          typeof vehicle === 'string' &&
          !option._name.includes((vehicle as string).toUpperCase())
        ) {
          return false;
        }
        return true;
      });
    }
    return filteredVehicles;
  }

  private filterGroups(groupName: string): Group[] {
    if (typeof groupName === 'string') {
      const filterValue = groupName.toLowerCase();
      return this.groups.filter((option) => {
        if (option.groupName) {
          return option.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  private buildTripQuery(): TripQuery {
    const tripQuery: TripQuery = {
      driver: this.selectedUser ? { id: this.selectedUser.id } : undefined,
      vehicle: this.selectedVehicle
        ? { id: this.selectedVehicle.id }
        : undefined,
      group: this.selectedGroup
        ? { groupIdentifier: this.selectedGroup.groupIdentifier }
        : undefined,
      startTime: this.myControlStartDate.value,
      endTime: this.myControlEndDate.value,
    };
    return tripQuery;
  }

  public queryTrips(): void {
    this.tripsData.queryBy(this.buildTripQuery());
    this.setSelectAll(false);
  }

  public onChangeSort(indexChange: number): void {
    // Remove sort direction icon from table sort
    this.tableSortDirection = '';

    // Always just one arrow can be set. Sequence: south (down) -> north (up) -> no arrow -> south ...
    let nextSort: Sort;
    for (let i = 0; i < this.sortButtons.length; i += 1) {
      if (i === indexChange) {
        if (this.sortButtons[i].sortDirection === '') {
          this.sortButtons[i].sortDirection = 'south';
          nextSort = { property: this.sortButtons[i].sortQuery, order: 'desc' };
        } else if (this.sortButtons[i].sortDirection === 'south') {
          this.sortButtons[i].sortDirection = 'north';
          nextSort = { property: this.sortButtons[i].sortQuery, order: 'asc' };
        } else {
          this.sortButtons[i].sortDirection = '';
          nextSort = { property: this.sortButtons[i].sortQuery, order: null };
        }
        this.tripsData.sortBy(nextSort);
      } else {
        this.sortButtons[i].sortDirection = '';
      }
    }
    this.setSelectAll(false);
  }

  public showAnalysis(tripResponse: TripResponse): void {
    this.currentlyDisplayedTrip = tripResponse;
    const dialogRef = this.dialog.open(TripAnalysisDialogComponent, {
      width: '80%',
      data: {
        tripResponse,
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.currentlyDisplayedTrip = null;
      this.cdr.detectChanges();
    });
  }

  public showMultipleAnalysis(tripResponses: TripResponse[]): void {
    this.dialog.open(TripMultipleDetailsComponent, {
      data: {
        tripResponses,
        isDispatcher: this.authService.isDispatcher(),
        isAdmin: this.authService.isAdmin(),
        showCompare: tripResponses.length <= this.compareCountMax,
      },
      disableClose: false,
    });
  }

  public displayUser(user: User): string {
    return user && user.userName ? user.userName : '';
  }

  public displayVehicle(vehicle: Vehicle): string {
    return vehicle && vehicle.name ? vehicle.name : '';
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public resizePage(pageSize: number): void {
    if (this.tripsData.pageSize !== pageSize) {
      this.tripsData.fetch(0, pageSize);
    }
  }

  public selectUser(user: User): void {
    this.selectedUser = user;
    this.queryTrips();
  }

  public unselectUser(): void {
    this.selectedUser = null;
    this.myControlUser.reset();
    this.queryTrips();
  }

  public selectVehicle(vehicle: Vehicle): void {
    this.selectedVehicle = vehicle;
    this.queryTrips();
  }

  public unselectVehicle(): void {
    this.selectedVehicle = null;
    this.myControlVehicle.reset();
    this.queryTrips();
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    this.queryTrips();
  }

  public unselectGroup(): void {
    this.selectedGroup = null;
    this.myControlGroup.reset();
    this.queryTrips();
  }

  public sortChanged(property: string, order: SortDirection): void {
    // Remove sort direction icons from sort buttons
    this.sortButtons.forEach((element) => {
      element.sortDirection = ''; // eslint-disable-line no-param-reassign
    });

    // Start table sort
    this.tripsData.sortBy({ property, order });
    this.setSelectAll(false);
  }

  public isAllSelected(): boolean {
    const value =
      this.selection.hasValue() &&
      this.selection.selected.length === this.tripsData.pageSize;
    return value;
  }

  public isAtLeastOneSelected(): boolean {
    const value =
      this.selection.hasValue() && this.selection.selected.length > 0;
    return value;
  }

  private detectChanges(): void {
    if (
      this.headerMatCheckBox !== undefined &&
      this.selection.selected.length <= 0 &&
      this.headerMatCheckBox.checked
    ) {
      this.headerMatCheckBox.toggle();
    }
    this.cdr.detectChanges();
  }

  private setSelectAll(selected: boolean): void {
    if (this.rows) {
      for (let index = 0; index < this.rows.length; index += 1) {
        const row = this.rows[index];
        if (selected) {
          this.selection.select(row);
        } else {
          this.selection.deselect(row);
        }
      }
    }
    this.detectChanges();
  }

  public deselectAll(): void {
    this.setSelectAll(false);
  }

  public disableActionButtons(): boolean {
    return !this.isAtLeastOneSelected() || this.ctr.isRunning;
  }

  public disableCompare(): boolean {
    return this.selection.selected.length < this.compareCountMin;
  }

  private isTripResponseValid(trip: TripResponse): boolean {
    return trip?.calculation?.distance > 0 && trip?.endTime?.getTime() > 0;
  }

  private onlyValidTripResponses(trips: TripResponse[]): TripResponse[] {
    const validTrips = [];
    for (let index = 0; index < trips.length; index += 1) {
      const trip = trips[index];
      if (this.isTripResponseValid(trip)) {
        validTrips.push(trip);
      }
    }
    return validTrips;
  }

  public showCompare(): boolean {
    return (
      this.onlyValidTripResponses(this.selection.selected).length <=
      this.compareCountMax
    );
  }

  public showComputeAverageValues(): boolean {
    return (
      this.onlyValidTripResponses(this.selection.selected).length >
      this.compareCountMax
    );
  }

  public masterToggle() {
    this.setSelectAll(!this.isAtLeastOneSelected());
  }

  public rowToggle(row: TripResponse): void {
    this.selection.toggle(row);
  }

  public executeAction(actionType: ConfirmationDialogAction): void {
    if (this.ctr.isRunning) {
      return;
    }
    this.ctr = TripsListComponent.getComputingTripResponses(actionType);
    switch (actionType) {
      case ConfirmationDialogAction.delete: {
        this.ctr.actionFunction = this.deleteTrip.bind(this);
        this.ctr.deselect = true;
        this.ctr.fetch = true;
        break;
      }
      case ConfirmationDialogAction.recalculate: {
        this.ctr.actionFunction = this.recalculateTrip.bind(this);
        this.ctr.deselect = true;
        this.ctr.fetch = true;
        break;
      }
      case ConfirmationDialogAction.downloadKml: {
        this.ctr.actionFunction = this.downloadTripAsKml.bind(this);
        break;
      }
      case ConfirmationDialogAction.downloadCsv: {
        this.ctr.actionFunction = this.downloadTripAsCsv.bind(this);
        break;
      }
      case ConfirmationDialogAction.compare: {
        this.ctr.runWithoutConfirmation = true;
        this.ctr.runOnce = true;
        this.ctr.noResult = true;
        this.ctr.actionFunction = this.compare.bind(this);
        break;
      }
      default: {
        this.ctr.actionType = ConfirmationDialogAction.unknown;
        break;
      }
    }
    if (this.ctr.actionType !== ConfirmationDialogAction.unknown) {
      this.ctr.tripResponses = this.selection.selected;
      this.ctr.toCompute = this.selection.selected.length;
      if (!this.ctr.noResult) {
        this.ctr.actionCallback = this.actionCallback.bind(this);
      }
      this.askConfirmationAndRun();
    }
  }

  private static tripResponseToTrip(tripResponse: TripResponse): Trip {
    const trip: Trip = {
      id: tripResponse.id,
      groupName: tripResponse.groupName,
      startTime: tripResponse.startTime.getTime(),
      vehicleId: tripResponse.vehicle.id,
      userId: tripResponse.userId,
    };
    return trip;
  }

  private deleteTrip(trip: Trip): Observable<Trip> {
    return this.tripService.deleteTrip(trip.groupName, trip.id);
  }

  private recalculateTrip(
    tripResponse: TripResponse
  ): Observable<RecalculatedTrip> {
    return this.tripService.recalculateTrip(tripResponse, true);
  }

  private downloadTripAsKml(trip: Trip): Observable<Download> {
    return this.tripService.downloadTrip(trip, DownloadType.kml);
  }

  private downloadTripAsCsv(trip: Trip): Observable<Download> {
    return this.tripService.downloadTrip(trip, DownloadType.csv);
  }

  private compare(): Observable<number> {
    return new Observable((observer) => {
      this.showMultipleAnalysis(
        this.onlyValidTripResponses(this.selection.selected)
      );
      observer.next(this.selection.selected.length);
      observer.complete();
    });
  }

  private askConfirmationAndRun(): void {
    if (this.ctr.runWithoutConfirmation) {
      TripsListComponent.executeActionFunction(this.ctr);
      return;
    }
    const data: ConfirmationDialogData = {
      action: this.ctr.actionType,
      entity: null,
      data: null,
    };
    if (this.ctr.toCompute > 1) {
      data.data = `${this.ctr.toCompute} ${this.translate.instant(
        'TRIP.TRIPS'
      )}`;
    } else {
      data.data = `${this.ctr.toCompute} ${this.translate.instant(
        'TRIP.TITLE'
      )}`;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '40%',
      data,
    });
    dialogRef.afterClosed().subscribe((doit) => {
      if (doit) {
        TripsListComponent.executeActionFunction(this.ctr);
      } else {
        this.ctr.toCompute = 0;
        this.detectChanges();
      }
    });
  }

  private static executeActionFunctionEnd(cd: ComputingTripResponses): void {
    // eslint-disable-next-line no-param-reassign
    cd.isReady = true;
    // eslint-disable-next-line no-param-reassign
    cd.isRunning = false;
    if (cd.cancel) {
      // eslint-disable-next-line no-param-reassign
      cd.isCanceld = true;
    }
    if (cd.actionCallback !== null) {
      cd.actionCallback(cd);
    }
  }

  private static executeActionCallback(cd: ComputingTripResponses): boolean {
    let cancel = false;
    if (cd.actionCallback !== null && cd.actionCallback(cd)) {
      cancel = true;
      TripsListComponent.executeActionFunctionEnd(cd);
    }
    return cancel;
  }

  private static executeActionFunction(cd: ComputingTripResponses): void {
    if (cd.runOnce) {
      // eslint-disable-next-line no-param-reassign
      cd.toCompute = 1;
    }
    if (cd.cancel || cd.index >= cd.toCompute) {
      TripsListComponent.executeActionFunctionEnd(cd);
    } else {
      // eslint-disable-next-line no-param-reassign
      cd.isReady = false;
      // eslint-disable-next-line no-param-reassign
      cd.isRunning = true;
      // eslint-disable-next-line no-param-reassign
      cd.index += 1;
      if (!TripsListComponent.executeActionCallback(cd)) {
        const trip = TripsListComponent.tripResponseToTrip(
          cd.tripResponses[cd.index - 1]
        );
        cd.actionFunction(trip).subscribe(
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
          () => {
            // eslint-disable-next-line no-param-reassign
            cd.computedError += 1;
            TripsListComponent.executeActionFunction(cd);
          },
          () => {
            // eslint-disable-next-line no-param-reassign
            cd.computedOk += 1;
            TripsListComponent.executeActionFunction(cd);
          }
        );
      }
    }
  }

  private fetchTrips(setSelectAll?: boolean): void {
    // this.tripsData.setLoading(true);
    this.tripsData.fetch();
    // this.tripsData.setLoading(false);
    if (setSelectAll !== undefined && setSelectAll !== null) {
      this.setSelectAll(setSelectAll);
    }
  }

  private actionCallback(cd: ComputingTripResponses): boolean {
    if (cd.isReady) {
      if (cd.deselect) {
        this.setSelectAll(false);
      }
      if (cd.fetch) {
        this.fetchTrips(false);
      }
    }
    this.detectChanges();
    return cd.cancel;
  }

  public isNotConfirmed(): boolean {
    const value =
      this.ctr.toCompute > 0 && !this.ctr.isConfirmed && !this.ctr.noResult;
    NavigationComponent.setDisabled(value);
    FooterComponent.setDisabled(value);
    return value;
  }

  public getCellClass(vehicleType: VehicleType): string {
    if (this.vehicleService.isElectric(vehicleType)) {
      return 'electricCell';
    }
    return null;
  }

  public fetch(event: PageEvent): void {
    this.tripsData.fetch(event.pageIndex, event.pageSize);
  }
}
