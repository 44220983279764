import {
  HttpClient,
  HttpParams,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { RxStompService } from '@stomp/ng2-stompjs';
import {
  RecalculatedTrip,
  Trip,
  TripResponse,
  TripEvaluation,
  Vehicle,
  PositionRequest,
} from 'lcmm-lib-js';
import { Message } from '@stomp/stompjs';
import { tap, map } from 'rxjs/operators';
import { PageRequest, Page } from '../datasource/page';
import { CustomHttpParamEncoder } from '../utils/custom-http-param-encoder';
import { EnvConfigurationService } from './env-config.service';
import { DownloadService, Download, DownloadType } from './download.service';
import { AuthService } from './auth.service';
import { CallbackEventType, ChartEventService } from './chart-event.service';
import { updateTripByTripResponse } from '../utils/utils';

export interface TripSectionParameter {
  caller: string;
  time: {
    startTime?: Date;
    endTime?: Date;
  };
  coord: {
    ne?: {
      lat: number;
      lng: number;
    };
    sw?: {
      lat: number;
      lng: number;
    };
  };
  tripPositions?: number;
}

export interface TripQuery {
  driver: { id: string };
  vehicle: { id: string };
  group: { groupIdentifier: string };
  startTime: Date;
  endTime: Date;
}

export interface TripRoutesRequest {
  coordinates: [number[], number[]];
  elevation: string;
  instructions: boolean;
  language: string;
  alternative_routes: {
    target_count: number; // Number of alternative routes
    weight_factor: number; // Weight factor for alternatives
  };
}

export interface TripRoutesDirection {
  id?: string;
  userId?: string;
  group?: string;
  vehicleId?: string;
  startCoordinate?: {
    lat?: number;
    lon?: number;
  };
  endCoordinate?: {
    lat?: number;
    lon?: number;
  };
  startTime?: string;
  endTime?: string;
  tripIds?: string[];
  alternatives?: number;
  elevation?: string;
  instructions?: boolean;
  language?: string;
  alternative_routes?: {
    target_count: number; // Number of alternative routes
    weight_factor: number; // Weight factor for alternatives
  };
}

@Injectable({
  providedIn: 'root',
})
export class TripService {
  private URL: string;

  private WS_URL: string;

  private _trip = new BehaviorSubject<Trip>(null);

  private readonly trip = this._trip.asObservable();

  private stompSession: Subscription = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private stomp: RxStompService,
    public envService: EnvConfigurationService,
    public downloadService: DownloadService,
    private ces: ChartEventService
  ) {
    this.URL = envService.config.tripManagementUrl;
    this.WS_URL = envService.config.tripManagementUrlWs;
  }

  private static _createTripSectionParameter(
    caller: string,
    startTime?: Date,
    endTime?: Date,
    boundNElat?: number,
    boundNElng?: number,
    boundSWlat?: number,
    boundSWlng?: number
  ): TripSectionParameter {
    const tsp: TripSectionParameter = {
      caller,
      time: {
        startTime,
        endTime,
      },
      coord: {
        ne: {
          lat: boundNElat,
          lng: boundNElng,
        },
        sw: {
          lat: boundSWlat,
          lng: boundSWlng,
        },
      },
    };
    if (
      (boundNElat === undefined || boundNElat === null) &&
      (boundNElng === undefined || boundNElng === null)
    ) {
      tsp.coord.ne = undefined;
    }
    if (
      (boundSWlat === undefined || boundSWlat === null) &&
      (boundSWlng === undefined || boundSWlng === null)
    ) {
      tsp.coord.sw = undefined;
    }
    return tsp;
  }

  public static createTripSectionParameter(
    caller: string
  ): TripSectionParameter {
    return this._createTripSectionParameter(caller);
  }

  public static createTripSectionParameterByTime(
    caller: string,
    startTime?: Date,
    endTime?: Date
  ): TripSectionParameter {
    return this._createTripSectionParameter(caller, startTime, endTime);
  }

  public static createTripSectionParameterByCoord(
    caller: string,
    boundNElat: number,
    boundNElng: number,
    boundSWlat: number,
    boundSWlng: number
  ): TripSectionParameter {
    return this._createTripSectionParameter(
      caller,
      null,
      null,
      boundNElat,
      boundNElng,
      boundSWlat,
      boundSWlng
    );
  }

  public static createTripRoutesDirection(
    userId: string,
    vehicle: Vehicle,
    alternatives: number,
    startlat: number,
    startlng: number,
    endlat: number,
    endlng: number
  ): TripRoutesDirection {
    const td: TripRoutesDirection = {
      userId,
      group: vehicle ? vehicle.groupName : undefined,
      vehicleId: vehicle ? vehicle.id : undefined,
      alternatives,
      startCoordinate: {
        lat: startlat,
        lon: startlng,
      },
      endCoordinate: {
        lat: endlat,
        lon: endlng,
      },
      elevation: 'true',
      instructions: true,
      language: 'de',
      alternative_routes: {
        target_count: alternatives,
        weight_factor: 1.2,
      },
    };
    return td;
  }

  private enhanceUrl(
    caller: string,
    url: string,
    additionalParameter: boolean,
    sectionParameter: TripSectionParameter
  ): string {
    let section = '';
    if (sectionParameter !== undefined && sectionParameter !== null) {
      let d = '?';
      if (additionalParameter) {
        d = '&';
      }
      if (
        sectionParameter.time !== undefined &&
        sectionParameter.time !== null
      ) {
        if (
          sectionParameter.time.startTime !== undefined &&
          sectionParameter.time.startTime !== null
        ) {
          section += `${d}from=${sectionParameter.time.startTime.toISOString()}`;
          d = '&';
        }
        if (
          sectionParameter.time.endTime !== undefined &&
          sectionParameter.time.endTime !== null
        ) {
          section += `${d}to=${sectionParameter.time.endTime.toISOString()}`;
          d = '&';
        }
      }
      if (
        sectionParameter.coord !== undefined &&
        sectionParameter.coord !== null
      ) {
        if (
          sectionParameter.coord.ne !== undefined &&
          sectionParameter.coord.ne !== null
        ) {
          section += `${d}northEastCoord=${sectionParameter.coord.ne.lat},${sectionParameter.coord.ne.lng}`;
          d = '&';
        }
        if (
          sectionParameter.coord.sw !== undefined &&
          sectionParameter.coord.sw !== null
        ) {
          section += `${d}southWestCoord=${sectionParameter.coord.sw.lat},${sectionParameter.coord.sw.lng}`;
          d = '&';
        }
        section += `${d}cutToBounds=true`;
        d = '&';
      }
    }
    return url + section;
  }

  public getTrip(
    groupName: string,
    tripId: string,
    detailed: boolean,
    sectionParameter?: TripSectionParameter
  ): Observable<TripResponse> {
    let url = `${this.URL}/groups/${groupName}/trips/${tripId}?detailed=${detailed}&limitOutput=false`;
    if (sectionParameter) {
      url = this.enhanceUrl('##getDetailedTrip', url, true, sectionParameter);
    }
    return this.http.get<TripResponse>(url);
  }

  public getDetailedTrip(
    groupName: string,
    tripId: string,
    sectionParameter: TripSectionParameter
  ): Observable<TripResponse> {
    return this.getTrip(groupName, tripId, true, sectionParameter);
  }

  public getDetailedTrips(
    groupName: string,
    tripIds: string[]
  ): Observable<TripResponse> {
    return new Observable((observer) => {
      let tripIdsCount = tripIds.length;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any[] = [];
      for (let i = 0; i < tripIds.length; i += 1) {
        this.getDetailedTrip(groupName, tripIds[i], null).subscribe(
          // eslint-disable-next-line no-loop-func
          (response) => {
            observer.next(response);
            tripIdsCount -= 1;
            if (tripIdsCount <= 0) {
              if (errors.length <= 0) {
                observer.complete();
              } else {
                observer.error(errors);
              }
            }
          },
          // eslint-disable-next-line no-loop-func
          (err) => {
            errors.push(err);
            tripIdsCount -= 1;
            if (tripIdsCount <= 0) {
              observer.error(errors);
            }
          }
        );
      }
    });
  }

  public deleteTrip(groupName: string, tripId: string): Observable<Trip> {
    return this.http.delete<Trip>(
      `${this.URL}/groups/${groupName}/trips/${tripId}`
    );
  }

  // TODO: Add User/Group? where empty trips shall be
  public deleteEmptyTrips(withPositions?: boolean): Observable<TripResponse[]> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (withPositions) {
      params.set('withPositions', 'true');
    }
    return this.http.delete<TripResponse[]>(`${this.URL}/admin/trips/empty`, {
      params: {
        withPositions: withPositions.toString(),
      },
    });
  }

  public recalculateTrip(
    trip: TripResponse,
    persist: boolean
  ): Observable<RecalculatedTrip> {
    return new Observable<RecalculatedTrip>((observer) => {
      this.http
        .get<RecalculatedTrip>(
          `${this.URL}/groups/${trip.groupName}/trips/${trip.id}/recalculate`,
          {
            params: {
              persist: persist.toString(),
            },
          }
        )
        .subscribe(
          (recalTrip: RecalculatedTrip) => {
            if (persist) {
              updateTripByTripResponse(trip, recalTrip.newTrip);
            }
            observer.next(recalTrip);
          },
          (err) => {
            observer.error(err);
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  public recalculateTrips(
    startTimeFrom: Date,
    startTimeTo: Date,
    groupIdentifier?: string
  ): Observable<TripResponse[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('from', startTimeFrom.toISOString());
    params = params.append('to', startTimeTo.toISOString());
    if (groupIdentifier !== undefined && groupIdentifier !== null) {
      params = params.append('group', groupIdentifier);
    }
    const recalObv = this.http.get<TripResponse[]>(
      `${this.URL}/admin/trips/recalculate`,
      { params }
    );
    return recalObv;
  }

  public getTrips(
    type: DownloadType,
    group: string,
    startTime?: Date,
    endTime?: Date,
    bounds?: google.maps.LatLngBounds,
    cutToBounds?: boolean
  ): Observable<Download> {
    let httpParams = new HttpParams();
    if (startTime) {
      httpParams = httpParams.append('from', startTime.toISOString());
    }
    if (endTime) {
      httpParams = httpParams.append('to', endTime.toISOString());
    }
    if (bounds) {
      httpParams = httpParams.append(
        'northEastCoord',
        `${bounds.getNorthEast().lat()},${bounds.getNorthEast().lng()}`
      );
      httpParams = httpParams.append(
        'southWestCoord',
        `${bounds.getSouthWest().lat()},${bounds.getSouthWest().lng()}`
      );
      httpParams = httpParams.append('cutToBounds', `${cutToBounds}`);
    }
    const url = `${this.URL}/groups/${group}/trips/${type}`;
    let defaultFileName: string;
    switch (type) {
      case DownloadType.csv: {
        defaultFileName = `trip_overview_${group}.${type}`;
        break;
      }
      case DownloadType.zip: {
        defaultFileName = `trips_${group}.${type}`;
        break;
      }
      default: {
        return null;
      }
    }
    return this.downloadService.downloadFile(
      url,
      (event) => {
        const contentDisposition = event.headers.get('Content-Disposition');
        if (!contentDisposition) {
          return defaultFileName;
        }
        const regex = /filename=(.*)/;
        return contentDisposition.match(regex)[1];
      },
      httpParams
    );
  }

  public getComparableTrips(
    limit: number,
    groupIdentifier: string,
    startTimeFrom: Date,
    startTimeTo: Date,
    bounds?: google.maps.LatLngBounds,
    cutToBounds?: boolean
  ): Observable<TripResponse[]> {
    let params: HttpParams = new HttpParams();
    if (groupIdentifier) params = params.append('group', groupIdentifier);
    if (startTimeFrom)
      params = params.append('from', startTimeFrom.toISOString());
    if (startTimeTo) params = params.append('to', startTimeTo.toISOString());
    if (bounds) {
      params = params.append(
        'northEastCoord',
        `${bounds.getNorthEast().lat()},${bounds.getNorthEast().lng()}`
      );
      params = params.append(
        'southWestCoord',
        `${bounds.getSouthWest().lat()},${bounds.getSouthWest().lng()}`
      );
      params = params.append('cutToBounds', `${cutToBounds}`);
    }
    params = params.append('page', 0);
    params = params.append('size', limit);
    const url = `${this.URL}/trips/comparable`;
    return this.http.get<TripResponse[]>(url, { params });
  }

  public downloadTrip(
    trip: Trip,
    type: DownloadType,
    sectionParameter?: TripSectionParameter
  ): Observable<Download> {
    const url = this.enhanceUrl(
      '##downloadTrip',
      `${this.URL}/groups/${trip.groupName}/trips/${trip.id}/${type}`,
      false,
      sectionParameter
    );
    return this.downloadService.downloadFile(url, (event) => {
      const contentDisposition = event.headers.get('Content-Disposition');
      if (contentDisposition) {
        const regex = /filename=(.*)/;
        return contentDisposition.match(regex)[1];
      }
      return `Trip_${trip.id}.${type}`;
    });
  }

  private closeWebSocketOnFinishedTrip(
    trip: TripResponse,
    tripResponse: TripResponse
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      // eslint-disable-next-line no-param-reassign
      tripResponse.startTime = new Date(tripResponse.startTime);
      if (tripResponse.endTime !== undefined && tripResponse.endTime !== null) {
        // eslint-disable-next-line no-param-reassign
        tripResponse.endTime = new Date(tripResponse.endTime);
        if (
          tripResponse.calculation.positionCount <=
          tripResponse.positions.length
        ) {
          this.stopStream().then(() => {
            // eslint-disable-next-line no-param-reassign
            if (!trip.endTime) {
              this.ces.emit(CallbackEventType.tripEnd, tripResponse);
            }
            resolve();
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  public createDetailedTripWebSocket(trip: TripResponse): Observable<Trip> {
    this.stopStream().then(() => {
      this.authService.getToken().then((token) => {
        const destination = `/topic/groups/${trip.groupName}/trips/${trip.id}`;
        this.stomp.configure({
          brokerURL: this.WS_URL,
          connectHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.stompSession = this.stomp
          .watch(destination)
          .subscribe((message: Message) => {
            if (message && message.body) {
              const nextTrip: Trip = JSON.parse(message.body);
              this._trip.next(nextTrip);
              const nextTripResponse: TripResponse = JSON.parse(message.body);
              this.closeWebSocketOnFinishedTrip(trip, nextTripResponse);
            }
          });
        this.stomp.activate();
      });
    });
    return this.trip;
  }

  public async stopStream(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.stompSession !== null) {
        this.stompSession.unsubscribe();
      }
      resolve();
    });
  }

  public page(
    request: PageRequest,
    query: TripQuery
  ): Observable<Page<TripResponse>> {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });

    if (request.size) params = params.append('size', request.size.toString());
    if (request.requestContinuation) {
      params = params.append(
        'requestContinuation',
        request.requestContinuation
      );
    }
    if (request.sort.property && request.sort.order) {
      let sortText = request.sort.property;
      if (sortText === 'rank') sortText = 'absoluteCalculation.ecoIndex';
      if (sortText === 'distance') sortText = 'calculation.distance';
      if (sortText === 'fuelConsumption')
        sortText = 'absoluteCalculation.fuelConsumption';
      if (sortText === 'averageSpeed') sortText = 'calculation.averageSpeed';
      if (sortText === 'duration') sortText = 'calculation.duration';
      if (sortText === 'timeLost') sortText = 'calculation.timeLost';
      if (sortText === 'ecoIndex1') sortText = 'absoluteCalculation.ecoIndex1';
      if (sortText === 'ecoIndex4') sortText = 'absoluteCalculation.ecoIndex4';
      if (sortText === 'brakingIndex') sortText = 'calculation.brakingIndex';
      // Add order, if available
      if (request.sort.order) sortText = `${sortText},${request.sort.order}`;
      params = params.append('sort', sortText);
    }

    if (query.vehicle) params = params.append('vehicle', query.vehicle.id);
    if (query.driver) params = params.append('user', query.driver.id);
    if (query.group)
      params = params.append('group', query.group.groupIdentifier);
    if (query.startTime)
      params = params.append('from', query.startTime.toISOString());
    if (query.endTime)
      params = params.append('to', query.endTime.toISOString());

    const options = {
      params,
      observe: 'response' as 'body',
    };

    return this.http
      .get<HttpResponse<TripResponse[]>>(`${this.URL}/trips`, options) // TODO: .retry(3) anhängen?
      .pipe(
        tap((data) => {
          data.body.forEach((element) => {
            if (element.startTime) {
              if (element.startTime.toString().startsWith('+')) {
                // eslint-disable-next-line no-param-reassign
                element.startTime = undefined;
              } else {
                // eslint-disable-next-line no-param-reassign
                element.startTime = new Date(element.startTime);
              }
            }
            if (element.endTime) {
              if (element.endTime.toString().startsWith('+')) {
                // eslint-disable-next-line no-param-reassign
                element.endTime = undefined;
              } else {
                // eslint-disable-next-line no-param-reassign
                element.endTime = new Date(element.endTime);
              }
            }
          });
        }),
        map((data: HttpResponse<TripResponse[]>) => {
          this.lastTripsPage = {
            content: data.body,
            number: request.page, // Aktuelle Seitenzahl
            size: request.size, // Anzahl angezeigter Einträge
            totalElements: Number(data.headers.get('X-Total-Count')),
            continuationNextPage: data.headers.get('x-continuation-token'),
          };
          return this.lastTripsPage;
        })
        // , catchError(this.handleError.bind(this))
      );
  }

  private lastTripsPage: Page<TripResponse> = null;

  public getLastTripsPage(): Page<TripResponse> {
    return this.lastTripsPage;
  }

  private handleError(
    error: HttpErrorResponse
    // , caught: Observable<Page<TripResponse>>
  ) {
    // console.error('##TripService.page handleError err?', error);
    // console.error('##TripService.page handleError caught?', caught);
    return throwError(error);
  }

  public getRandomTrip(): Observable<TripResponse> {
    return new Observable((observer) => {
      this.stopStream().then(() => {
        this.http.get<TripResponse>(`${this.URL}/admin/trips/random`).subscribe(
          (trip) => {
            observer.next(this.mapTripResponse(trip));
          },
          (err) => {
            observer.error(err);
          },
          () => {
            observer.complete();
          }
        );
      });
    });
  }

  public submitEvaluation(tripEvalution: TripEvaluation) {
    return this.http.post(`${this.URL}/admin/trips/evaluation`, tripEvalution);
  }

  private mapTripResponse(tripResponse: TripResponse): TripResponse {
    const tr: TripResponse = tripResponse;
    tr.startTime = new Date(tr.startTime);
    tr.endTime = new Date(tr.endTime);
    return tr;
  }

  public createRoutes(
    tripDirection: TripRoutesDirection
  ): Observable<TripRoutesDirection> {
    return new Observable<TripRoutesDirection>((observer) => {
      const url = `${this.URL}/trips/groups/${tripDirection.group}/vehicles/${tripDirection.vehicleId}/createRoutes`;
      const payload: TripRoutesRequest = {
        coordinates: [
          [
            tripDirection.startCoordinate.lon,
            tripDirection.startCoordinate.lat,
          ],
          [tripDirection.endCoordinate.lon, tripDirection.endCoordinate.lat],
        ],
        elevation: tripDirection.elevation,
        instructions: tripDirection.instructions,
        language: tripDirection.language,
        alternative_routes: tripDirection.alternative_routes,
      };
      this.http.post(url, payload).subscribe(
        (response) => {
          const resp: TripRoutesDirection = response as TripRoutesDirection;
          observer.next(resp);
        },
        (err) => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  public startTrip(trip: TripResponse): Observable<TripResponse> {
    return new Observable<TripResponse>((observer) => {
      const url = `${this.URL}/groups/${trip.groupName}/vehicles/${trip.vehicle.id}/start-trip`;
      this.http.post(url, null).subscribe(
        (response) => {
          observer.next(response as TripResponse);
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  public uploadTrip(tripResponse: TripResponse): Observable<number> {
    return new Observable((observer) => {
      const positionRequest: PositionRequest = {
        positions: tripResponse.positions,
        tripId: tripResponse.id,
        userId: tripResponse.userId,
        vehicleGroup: tripResponse.groupName,
        vehicleId: tripResponse.vehicle.id,
      };
      const devFunctionHost = 'https://ldevappconnectorfunc.azurewebsites.net';
      const url = `${devFunctionHost}/api/import-positions`;
      try {
        this.http.post(url, positionRequest).subscribe(
          () => {
            observer.next(positionRequest.positions.length);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      } catch (error) {
        observer.error(error);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stopTrip(tripResponse: TripResponse): Observable<any> {
    const url = `${this.URL}/groups/${tripResponse.groupName}/trips/${tripResponse.id}/stop`;
    const params = new HttpParams().set(
      'endTime',
      tripResponse.endTime.toISOString()
    );
    const options = { params };
    return this.http.put(url, null, options);
  }
}
