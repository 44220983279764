import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Group, TripResponse } from 'lcmm-lib-js';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/service/auth.service';
import { Download } from 'src/app/service/download.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { TripService } from 'src/app/service/trip.service';
import { UserService } from 'src/app/service/user.service';
import { DateInputErrorStateMatcher } from 'src/app/utils/error-state-matcher';
import { sortString } from 'src/app/utils/utils';
import { TripBatchResultsComponent } from './trip-batch-results/trip-batch-results.component';
import { TripAnalysisDialogComponent } from '../trips-list/trip-analysis-dialog/trip-analysis-dialog.component';
import { TripRoutingComponent } from '../trip-routing/trip-routing.component';

interface SearchCriteria {
  startTimeFrom?: Date;
  startTimeTo?: Date;
  groupIdentifier?: string;
}

@Component({
  selector: 'app-trips-tools',
  templateUrl: './trips-tools.component.html',
  styleUrls: ['./trips-tools.component.scss'],
})
export class TripsToolsComponent implements OnInit {
  public GROUP_IDENTIFIER_SEPARATOR: string;

  private searchCriteria: SearchCriteria = {
    startTimeFrom: null,
    startTimeTo: null,
    groupIdentifier: null,
  };

  public busy: boolean;

  public withPositions = false;

  public maxDate: Date = new Date();

  public groups: Group[];

  public formControlGroup = new UntypedFormControl();

  public myControlStartDate = new UntypedFormControl(null);

  public myControlEndDate = new UntypedFormControl(null);

  public myControlCsvStartDate = new UntypedFormControl(null);

  public myControlCsvEndDate = new UntypedFormControl(null);

  public filteredGroups: Observable<Group[]>;

  public selectedGroup?: Group;

  public downloadFile$: Observable<Download>;

  @Input() public userRole: string;

  public loading = false;

  public startDateValid = true;

  public endDateValid = true;

  public startCsvDateValid = true;

  public endCsvDateValid = true;

  public errorStateMatcher = new DateInputErrorStateMatcher();

  public errorStateMatcherCsv = new DateInputErrorStateMatcher();

  public selectedGroupShowTrip?: Group;

  public tripIdShowTrip: string;

  public tripIdShowTripErrorMsg: string;

  constructor(
    private tripService: TripService,
    public dialog: MatDialog,
    private userService: UserService,
    public envService: EnvConfigurationService,
    public authService: AuthService
  ) {
    this.tripIdShowTrip = null;
    this.tripIdShowTripErrorMsg = null;
    this.GROUP_IDENTIFIER_SEPARATOR =
      envService.config.groupIdentifierSeparator;
  }

  ngOnInit(): void {
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups.sort(sortString('groupIdentifier'));
        this.filteredGroups = this.formControlGroup.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });

    this.myControlStartDate.statusChanges.subscribe((status) => {
      this.startDateValid = status === 'VALID';
    });

    this.myControlEndDate.statusChanges.subscribe((status) => {
      this.endDateValid = status === 'VALID';
    });

    this.myControlCsvStartDate.statusChanges.subscribe((status) => {
      this.startCsvDateValid = status === 'VALID';
    });

    this.myControlCsvEndDate.statusChanges.subscribe((status) => {
      this.endCsvDateValid = status === 'VALID';
    });
  }

  public recalcSubmit(): void {
    const criteria = { ...this.searchCriteria };
    this.tripService
      .recalculateTrips(
        criteria.startTimeFrom,
        criteria.startTimeTo,
        criteria.groupIdentifier
      )
      .subscribe((recalTrips: TripResponse[]) => {
        // Open new window with results
        this.dialog.open(TripBatchResultsComponent, {
          width: '80%',
          data: { tripResults: recalTrips },
        });
      });
  }

  public isRecalcSubmitDisabled(): boolean {
    return (
      this.searchCriteria.startTimeFrom === null ||
      this.searchCriteria.startTimeTo === null
    );
  }

  public clearEmptySubmit(): void {
    const dialogRefConfirmation = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '80%',
        data: 'DELETE',
      }
    );

    dialogRefConfirmation.afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.tripService
          .deleteEmptyTrips(this.withPositions)
          .subscribe((deletedTrips: TripResponse[]) => {
            // Open new window with results
            this.dialog.open(TripBatchResultsComponent, {
              width: '80%',
              data: { tripResults: deletedTrips },
            });
          });
      }
    });
  }

  public clearTripIdShowTrip(): void {
    this.tripIdShowTrip = null;
    this.tripIdShowTripErrorMsg = null;
  }

  public clearTripIdShowTripErrorMsg(): void {
    this.tripIdShowTripErrorMsg = null;
  }

  public disableShowTripButton(): boolean {
    return (
      this.isBusy() ||
      this.tripIdShowTripErrorMsg !== null ||
      !this.selectedGroupShowTrip ||
      !this.tripIdShowTrip ||
      this.tripIdShowTrip.length < 20
    );
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    if (group !== null) {
      this.searchCriteria.groupIdentifier = group.groupIdentifier;
    } else {
      this.searchCriteria.groupIdentifier = null;
    }
  }

  public selectGroupShowTrip(group: Group): void {
    this.clearTripIdShowTripErrorMsg();
    this.selectedGroupShowTrip = group;
    if (group !== null) {
      this.searchCriteria.groupIdentifier = group.groupIdentifier;
    } else {
      this.searchCriteria.groupIdentifier = null;
    }
  }

  public isGroupSelected(): boolean {
    if (this.selectedGroup) {
      return true;
    }
    return false;
  }

  public isGroupShowTripSelected(): boolean {
    if (this.selectedGroupShowTrip) {
      return true;
    }
    return false;
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public intervalChange(interval: SearchCriteria): void {
    if (interval.startTimeFrom) {
      this.searchCriteria.startTimeFrom = interval.startTimeFrom;
    }
    if (interval.startTimeTo) {
      this.searchCriteria.startTimeTo = interval.startTimeTo;
    }
  }

  private filterGroups(groupName: string): Group[] {
    if (typeof groupName === 'string') {
      const filterValue = groupName.toLowerCase();
      return this.groups.filter((option) => {
        if (option.groupName) {
          return option.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  public showTripDetailsByTripId(): void {
    const tripIds = this.parseTripIds(this.tripIdShowTrip);
    if (tripIds.length > 1) {
      this.showTripRoutingByTripId();
    } else {
      this.tripService
        .getDetailedTrip(
          this.selectedGroupShowTrip.groupIdentifier,
          tripIds[0],
          null
        )
        .subscribe(
          (trip) => {
            const tripResponse: TripResponse = trip;
            this.dialog.open(TripAnalysisDialogComponent, {
              width: '80%',
              data: {
                tripResponse,
                isSectionDialog: false,
                tripSectionParameter: null,
              },
              disableClose: false,
            });
          },
          () => {
            this.tripIdShowTripErrorMsg = 'TOOLS.TRIP_ID_UNKNOWN';
          }
        );
    }
  }

  private parseTripIds(text: string): string[] {
    const ids = text
      .replace(/\[/g, '')
      .replace(/\]/g, '')
      .replace(/"/g, '')
      .replace(/\s/g, '')
      .replace(/;/g, ',')
      .split(',')
      .filter((s) => s.length > 0);
    return [...new Set(ids)];
  }

  private setBusy(): void {
    this.busy = true;
  }

  private clearBusy(): void {
    this.busy = false;
  }

  public isBusy(): boolean {
    return this.busy;
  }

  public showTripRoutingByTripId(): void {
    const { groupIdentifier } = this.selectedGroupShowTrip;
    const tripIds = this.parseTripIds(this.tripIdShowTrip);
    const tripResponses: TripResponse[] = [];
    const routingMode = false;
    const hideSumAndAverage = false;
    let title = tripIds.length <= 1 ? 'TRIP.TITLE' : 'TRIP.TRIPS';
    title = routingMode ? 'TRIP.ROUTINGTIPS' : title;
    this.setBusy();
    this.tripService.getDetailedTrips(groupIdentifier, tripIds).subscribe(
      (tr) => {
        tripResponses.push(tr);
      },
      (err) => {
        this.clearBusy();
        this.tripIdShowTripErrorMsg = JSON.stringify(err);
      },
      () => {
        this.clearBusy();
        this.dialog.open(TripRoutingComponent, {
          width: '100%',
          height: '100%',
          data: {
            tripResponses,
            title,
            routingMode,
            hideSumAndAverage:
              tripResponses.length <= 1 ? true : hideSumAndAverage,
          },
          disableClose: false,
        });
      }
    );
  }
}
