import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NetworkService, NetworkStateEvent } from './network.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkHttpInterceptor implements HttpInterceptor {
  constructor(private ns: NetworkService) {}

  public intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    handler: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    this.ns.inform(NetworkStateEvent.LOADINGON);
    return handler.handle(req).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        const stateEvent =
          error.error instanceof ErrorEvent
            ? NetworkStateEvent.CLIENTERROR
            : NetworkStateEvent.SERVERERROR;
        this.ns.inform(stateEvent, error);
        return throwError(error);
      }),
      finalize(() => {
        this.ns.inform(NetworkStateEvent.LOADINGOFF);
      })
    );
  }
}
