import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstation-estm-dashboard',
  templateUrl: './sensorstation-estm-dashboard.component.html',
  styleUrl: './sensorstation-estm-dashboard.component.scss',
})
export class SensorstationEstmDashboardComponent implements OnInit, OnDestroy {
  public url: string;

  private resizeListener: () => void;

  constructor(
    public envService: EnvConfigurationService,
    private elementRef: ElementRef
  ) {
    const currentTime = new Date();

    // Adjust to the hour before the last full hour
    const referenceTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      currentTime.getHours() - 2, // Subtract 2 hours to get the full hour before the last one
      0,
      0,
      0 // Reset minutes, seconds, and milliseconds
    );

    const from = referenceTime.getTime();
    const to = from + 3600000;

    this.url = `${envService.config.grafanaUrl}d/ddvdur8vqe9z4f/estm?orgId=1&viewMode&from=${from}&to=${to}`;
  }

  // Method to change the URL dynamically
  public changeUrl(newUrl: string) {
    this.url = newUrl;
  }

  ngOnInit(): void {
    this.setIframeHeight();
    this.resizeListener = this.updateIframeHeight.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }

  private setIframeHeight(): void {
    const iframe = this.elementRef.nativeElement.querySelector(
      '#sensorstation-estm'
    ) as HTMLIFrameElement;
    if (iframe) {
      this.updateIframeHeight();
    }
  }

  private updateIframeHeight(): void {
    const iframe = this.elementRef.nativeElement.querySelector(
      '#sensorstation-estm'
    ) as HTMLIFrameElement;
    if (iframe) {
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      iframe.style.height = `${screenHeight - 230}px`;
    }
  }

  ngOnDestroy(): void {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }
}
