import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type ResizePageFunction = (pageSize: number) => void;

@Injectable({ providedIn: 'root' })
export class ResponsiveDesignService {
  private toObserve: string[] = [
    Breakpoints.Handset,
    Breakpoints.Tablet,
    '(min-width: 840px) and (max-width: 960px)',
  ];

  private defaultPageSize = 10;

  private handsetPageSize = 5;

  private resizeFunctions: Map<string, ResizePageFunction>;

  private isHandset$: Observable<boolean>;

  private handset = true;

  private pageSize: number;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.resizeFunctions = new Map<string, ResizePageFunction>();
    this.isHandset$ = this.breakpointObserver
      .observe(this.toObserve)
      .pipe(map((result) => result.matches));
    this.isHandset$.subscribe((value) => {
      this.handset = value;
      this._callBackOn(
        this.handset ? this.handsetPageSize : this.defaultPageSize
      );
    });
  }

  private _callBackOn(pageSize: number): void {
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
      this.resizeFunctions.forEach((value) => {
        value(pageSize);
      });
    }
  }

  public register(key: string, resizePageFunction: ResizePageFunction): number {
    this.resizeFunctions.set(key, resizePageFunction);
    return this.pageSize;
  }

  public deregister(key: string): void {
    this.resizeFunctions.delete(key);
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public isHandset(): boolean {
    return this.handset;
  }
}
