import { Injectable } from '@angular/core';

export enum NetworkStateEvent {
  CLIENTERROR,
  SERVERERROR,
  LOADINGON,
  LOADINGOFF,
  OFFLINE,
  ONLINE,
}

export type NetworkStateCallBack = (
  eventType: NetworkStateEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  msg?: any
) => void;

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public offlineOrLoading = true;

  public online = false;

  public loading = false;

  private consumer: Map<string, NetworkStateCallBack> = new Map();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setOnline(online: boolean): void {
    if (this.online !== online) {
      this.online = online;
      this.offlineOrLoading = this.loading || !this.online;
      if (online) {
        this._inform(NetworkStateEvent.ONLINE);
      } else {
        this._inform(NetworkStateEvent.OFFLINE);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setLoading(loading: boolean): void {
    if (this.loading !== loading) {
      this.loading = loading;
      this.offlineOrLoading = this.loading || !this.online;
      if (loading) {
        this._inform(NetworkStateEvent.LOADINGON);
      } else {
        this._inform(NetworkStateEvent.LOADINGOFF);
      }
    }
  }

  constructor() {
    window.addEventListener('online', () => {
      this.setOnline(true);
    });
    window.addEventListener('offline', () => {
      this.setOnline(false);
    });
    this.setOnline(navigator.onLine);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public inform(stateEvent: NetworkStateEvent, parameter?: any): void {
    switch (stateEvent) {
      case NetworkStateEvent.LOADINGON:
        this.setLoading(true);
        break;
      case NetworkStateEvent.LOADINGOFF:
        this.setLoading(false);
        break;
      case NetworkStateEvent.ONLINE:
        this.setOnline(true);
        break;
      case NetworkStateEvent.OFFLINE:
        this.setOnline(false);
        break;
      case NetworkStateEvent.CLIENTERROR:
      case NetworkStateEvent.SERVERERROR:
        this._inform(stateEvent, parameter);
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public _inform(stateEvent: NetworkStateEvent, parameter?: any): void {
    this.consumer.forEach((cbFn) => {
      /*
      this.consumer.forEach((cbFn, key) => {
        console.log(
        'NetworkStateEvent ',
        key,
        ' ',
        NetworkStateEvent[stateEvent]
      );
      */
      cbFn(stateEvent, parameter);
    });
  }

  public register(key: string, cbFn: NetworkStateCallBack): void {
    // console.log('register ', key);
    this.consumer.set(key, cbFn);
  }

  public deregister(key: string): void {
    // console.log('deregister ', key);
    this.consumer.delete(key);
  }
}
