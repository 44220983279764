<mat-card appearance="outlined" class="card">
  
  <mat-card-header>
    <mat-card-title>{{ 'SENSORSTATION.MAP.OVERVIEW' | translate }}</mat-card-title>
  </mat-card-header>
  
  <div class="grafana-container" style="text-align: center;">
    <iframe id="sensorstation-map"
    [src]="url | sensorstationsUrl"
    width="100%" height="560" frameborder="0" style="border:none; overflow:hidden;" 
    scrolling="no"></iframe>
  </div>
</mat-card>


