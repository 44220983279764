<app-header></app-header>

<mat-card appearance="outlined" class="mat-card">
  <div class="form-header">
  <mat-card-header>
    <mat-card-title>{{ 'CONTACT.TITLE' | translate}}</mat-card-title>
    <mat-card-subtitle>{{ 'CONTACT.SUBTITLE' | translate}}</mat-card-subtitle>
  </mat-card-header>
  </div>
  <div class="form">
  <mat-card-actions>
    <form [formGroup]="formData" #formDirective="ngForm" (ngSubmit)="submit(formDirective)">
    <mat-form-field appearance="outline" class="form-fields">
      <input matInput placeholder="{{ 'CONTACT.NAME' | translate}}" type="text" required formControlName="name" name="name"/>
      <mat-error *ngIf="formData.get('name').hasError('required')">
        {{ 'CONTACT.ERROR_INPUT' | translate}}
      </mat-error>
    </mat-form-field>
    <br>
    <mat-form-field appearance="outline" class="form-fields">
      <input matInput placeholder="{{ 'CONTACT.EMAIL' | translate}}" type="email" required formControlName="email" name="email"/>
      <mat-error *ngIf="formData.get('email').hasError('required')">
        {{ 'CONTACT.ERROR_INPUT' | translate}}
      </mat-error>
      <mat-error *ngIf="formData.get('email').hasError('email')">
        {{ 'CONTACT.ERROR_EMAIL' | translate}}
      </mat-error>
    </mat-form-field>
    <br>
    <mat-form-field appearance="outline" class="form-fields">
      <input matInput placeholder="{{ 'CONTACT.SUBJECT' | translate}}" type="text" required formControlName="subject" name="subject"/>
      <mat-error *ngIf="formData.get('subject').hasError('required')">
        {{ 'CONTACT.ERROR_INPUT' | translate}}
      </mat-error>
    </mat-form-field>
    <br>
    <div class="textarea-wrapper">


    <mat-form-field appearance="outline" class="form-fields" style="min-height: 210px !important;">
      <textarea  matInput cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="10"
      cdkAutosizeMaxRows="10" 
      (input)="updateCharCount()"
      [maxlength]="msgCharLength"
      placeholder="{{ 'CONTACT.MESSAGE' | translate}}" required formControlName="message"
      name="message"
      [(ngModel)] = "comment"></textarea>
      <span class="remaining">{{ remainingCharLength }} / {{ msgCharLength }}</span>
      <mat-error *ngIf="formData.get('message').hasError('required')">
        {{ 'CONTACT.ERROR_INPUT' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  
    <br>

    <button mat-raised-button color="primary" [disabled]="!formData.valid" >
      {{ 'CONTACT.SUBMIT' | translate}}
    </button>
  </form>
  </mat-card-actions>
</div>
</mat-card>

<div *ngIf="emailSent" class="alert alert-success">
  {{ 'CONTACT.MAIL_SUCCESS' | translate}}
</div>
<div *ngIf="emailError" class="alert alert-danger">
  {{ 'CONTACT.MAIL_ERROR' | translate}}
</div>

<app-footer></app-footer>