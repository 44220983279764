<mat-dialog-content>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="SENSORSTATION.DETAILS.DETAILS"></span>
        </ng-template>
        <ng-template matTabContent>
          <div class="sensorstation-container">
          <app-sensorstation-detail-view [sensorstation]="sensorstation"> </app-sensorstation-detail-view>
          <app-sensorstation-detail-map [sensorstation]="sensorstation"></app-sensorstation-detail-map>
        </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="SENSORSTATION.DETAILS.OVERVIEW"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-sensorstation-diagrams [sensorstation]="sensorstation"></app-sensorstation-diagrams>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  