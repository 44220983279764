<app-header></app-header>

<mat-card appearance="outlined" class="mat-mdc-card">
  <mat-card-title class="title" translate="IMPRINT.TITLE"></mat-card-title>
</mat-card>

<div class="wrapper">
  <ng-template ngFor let-ITEM [ngForOf]="'IMPRINT.ADDRESSES' | translate">
    <a class="adress">
      <mat-card appearance="outlined" class="mat-mdc-card" >
        <mat-card-title class="card-title" translate>{{ ITEM.TITLE }} </mat-card-title>
        <mat-card-content innerHTML="{{ ITEM.CONTENT }}"  style="font-size: 14px;"></mat-card-content>
      </mat-card>
    </a>
  </ng-template>
</div>

<app-footer></app-footer>
