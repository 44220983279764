import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Chart,
  registerables,
  Point,
  ScaleOptions,
  ChartConfiguration,
  ChartDataset,
  ChartOptions,
  ChartType,
  ScaleType,
} from 'chart.js';
import { CalculatedPosition, Trip } from 'lcmm-lib-js';
import { Observable, Subscription } from 'rxjs';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import {
  TripSectionParameter,
  TripService,
} from 'src/app/service/trip.service';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';

export interface ExtendedPoint extends Point {
  t: Date | number;
}
Chart.register(...registerables, zoomPlugin);
@Component({ template: '' })
export abstract class AbstractChartComponent
  implements OnInit, OnChanges, OnDestroy
{
  public xLabel: string;

  public yLabel: string;

  public chartType: ScaleType;

  public lineChartHeightPx = 400;

  public lineChartData: ChartDataset[] = [];

  public lineChartOptions: ChartOptions = {};

  public lineChartPlugins = [];

  public lineChartType: ChartType = 'line';

  public lineChartLabels: string[];

  public lineChartLegend: boolean;

  private subscription: Subscription;

  public sectionStart: Date = null;

  public sectionEnd: Date = null;

  public sectionMinInitial: number = null;

  public sectionMaxInitial: number = null;

  private lineChartPoints: Point[] = [];

  public sectionDetailsLoading = false;

  public dialogRefDetailsOpen = false;

  public isRangeChanged = false;

  private lastChangedTspSent: TripSectionParameter = null;

  private tripPositionCount: number = null;

  @Input() private trip: Trip;

  @Input() private tripObservable: Observable<Trip>;

  @Input() public isSectionDialog: boolean;

  constructor(
    protected translateService: TranslateService,
    public dialog: MatDialog,
    private ces: ChartEventService
  ) {
    this.lineChartHeightPx = ces.getAppHeightPx(40);
  }

  async ngOnInit(): Promise<void> {
    const translatedLabel: string = await this.translateService
      .get(this.yLabel)
      .toPromise();
    this.lineChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      scales: {
        y: {
          title: {
            display: true,
            text: translatedLabel,
          },
        },
        x: await this.getXAxes(),
      },
      height: 400,
      plugins: {
        legend: {
          display: false,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          zoom: {
            wheel: {
              enabled: true, // Enable zooming with mouse wheel
            },
            pinch: {
              enabled: true, // Enable zooming on touch devices
            },
            mode: 'x',
          },
        },
      },
    } as ChartConfiguration['options'];

    if (this.isSectionDialog) {
      this.readTrip(this.trip, translatedLabel);
    } else if (this.tripObservable) {
      this.subscription = this.tripObservable.subscribe(async (t) => {
        this.readTrip(t, translatedLabel);
      });
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.ces.emit(CallbackEventType.cleared);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.trip = undefined;
    this.lineChartData = [];
  }

  private readTrip(t: Trip, label: string): void {
    if (t && t.positions && (!this.trip || this.trip.id === t.id)) {
      this.tripPositionCount = t.calculation.positionCount;
      this.lineChartData = [];
      this.lineChartData.push(this.getChartData(t, label));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getChartData(trip: Trip, theLabel: string): ChartDataset {
    // eslint-disable-next-line no-param-reassign
    this.lineChartPoints = this.getData(trip.positions, trip);
    const chartData: ChartDataset = {
      borderColor: 'black',
      data: this.lineChartPoints,
      cubicInterpolationMode: 'monotone',
      tension: 0,
      pointRadius: 0,
      borderWidth: 1,
      pointHitRadius: 5,
      fill: false,
      label: theLabel,
    };
    return chartData;
  }

  protected abstract getData(
    positions: CalculatedPosition[],
    trip?: Trip
  ): Point[];

  public async getXAxes(): Promise<ScaleOptions<'linear' | 'time'>> {
    switch (this.chartType) {
      case 'linear': {
        return this.getXAxesLinear();
      }
      default: {
        return this.getXAxesTime();
      }
    }
  }

  public async getXAxesTime(): Promise<ScaleOptions<'time'>> {
    if (this.xLabel === undefined) {
      this.xLabel = 'TRIPS.XLABEL.TIME';
    }
    const translatedLabel: string = await this.translateService
      .get(this.xLabel)
      .toPromise();
    return {
      type: 'time',
      title: {
        display: true,
        text: translatedLabel,
      },
      time: {
        unit: 'minute',
        tooltipFormat: 'YYYY-MM-DD HH:mm:ss',
        displayFormats: {
          minute: 'H:mm',
        },
      },
      afterUpdate: (axis) => {
        this.handleResize(axis);
      },
    };
  }

  public async getXAxesLinear(): Promise<ScaleOptions<'linear'>> {
    if (this.xLabel === undefined) {
      this.xLabel = 'TRIPS.XLABEL.DISTANCE';
    }
    const translatedLabel: string = await this.translateService
      .get(this.xLabel)
      .toPromise();
    return {
      type: 'linear',
      title: {
        display: true,
        text: translatedLabel,
      },
      afterUpdate: (axis) => {
        this.handleResize(axis);
      },
    };
  }

  private searchStartTime(distMin: number): Date {
    let start: Date = new Date(this.trip.startTime);
    if (this.lineChartPoints.length > 0) {
      start = null;
      let last: number = null;
      for (let index = 0; index < this.lineChartPoints.length; index += 1) {
        const cp = this.lineChartPoints[index];
        if (cp.x === distMin) {
          return new Date(cp.x);
        }
        if (Number(cp.x) > distMin) {
          if (last !== null) {
            return new Date(last);
          }
          return new Date(cp.x);
        }
        last = cp.x;
        if (start === null) {
          start = new Date(cp.x);
        }
      }
    }
    return start;
  }

  private searchEndTime(distMax: number): Date {
    let end: Date = new Date(this.trip.endTime);
    for (let index = 0; index < this.lineChartPoints.length; index += 1) {
      const cp = this.lineChartPoints[index];
      if (Number(cp.x) >= distMax) {
        return new Date(cp.x);
      }
      end = new Date(cp.x);
    }
    return end;
  }

  private checkStartTime(time: Date): Date {
    if (
      this.lineChartPoints.length <= 0 ||
      time.getTime() < this.trip.startTime
    ) {
      return new Date(this.trip.startTime);
    }
    return time;
  }

  private checkEndTime(time: Date): Date {
    if (
      this.lineChartPoints.length <= 0 ||
      time.getTime() > this.trip.endTime
    ) {
      return new Date(this.trip.endTime);
    }
    return time;
  }

  private setIsRangeChanged(min: number, max: number): boolean {
    if (this.sectionMinInitial === null) {
      this.sectionMinInitial = min;
      this.sectionMaxInitial = max;
      this.isRangeChanged = false;
    } else {
      this.isRangeChanged =
        this.sectionMinInitial !== min || this.sectionMaxInitial !== max;
    }
    return this.isRangeChanged;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleResize(xaxis: any): void {
    if (this.isSectionDialog) {
      return;
    }
    if (this.setIsRangeChanged(xaxis.min, xaxis.max)) {
      if (xaxis.type === 'linear') {
        this.sectionStart = this.searchStartTime(xaxis.min);
        this.sectionEnd = this.searchEndTime(xaxis.max);
      }
      if (xaxis.type === 'time') {
        this.sectionStart = this.checkStartTime(new Date(xaxis.min));
        this.sectionEnd = this.checkEndTime(new Date(xaxis.max));
      }
      const tsp: TripSectionParameter =
        TripService.createTripSectionParameterByTime(
          `Chart ${this.yLabel} handleResize`,
          this.sectionStart,
          this.sectionEnd
        );
      tsp.tripPositions = this.tripPositionCount;
      if (JSON.stringify(this.lastChangedTspSent) !== JSON.stringify(tsp)) {
        this.lastChangedTspSent = { ...tsp };
        this.ces.emit(CallbackEventType.changed, tsp);
      }
    }
  }
}
