import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ts: TranslateService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const requiredRoles = route.data.roles || [];
    const userRoles = this.authService.getUserRoles(); // Call your AuthService's getUserRoles method

    if (this.checkRoles(requiredRoles, userRoles)) {
      return true;
    }

    const message = this.ts.instant('GUARD.ACCESSDENIED');
    const action = this.ts.instant('GUARD.ACTION');
    // Show error notification and redirect
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
    return this.router.createUrlTree(['/']); // Default to home if no previous URL // Redirect if roles don't match
  }

  private checkRoles(requiredRoles: string[], userRoles: string[]): boolean {
    return requiredRoles.some((role) => userRoles.includes(role));
  }
}
