import { DatePipe } from '@angular/common';
import { ElementRef } from '@angular/core';
import {
  MarkerClusterer,
  MarkerClustererOptions,
} from '@googlemaps/markerclusterer';
import { TranslateService } from '@ngx-translate/core';
import { FixFlag, Trip, TripResponse } from 'lcmm-lib-js';
import {
  TripSectionParameter,
  TripService,
  TripRoutesDirection,
} from 'src/app/service/trip.service';
import {
  MarkerPopup,
  StandStillMarkerRenderer,
} from 'src/app/utils/markers/stand-still-marker-renderer';
import { secondsToDuration, tripResponseToTrip } from '../utils';

export type SectionCallback = (section: TripSectionParameter) => unknown;

export type DirectionCallback = (direction: TripRoutesDirection) => unknown;

export type Bounds = {
  latMax: number;
  lngMax: number;
  latMin: number;
  lngMin: number;
};

export type Line = {
  trip: Trip;
  polylinesUnselected: google.maps.Polyline[];
  polylines: google.maps.Polyline[];
};

export class MapHelper {
  private bounds = StandStillMarkerRenderer.getBoundsEurope();

  private currentBounds: Bounds;

  private boundsPadding = 0.1;

  private isSectionAllowed: boolean = null;

  private isDirectionMap = false;

  private map: google.maps.Map = null;

  private mapListener: google.maps.MapsEventListener = null;

  private directionAlternatives = 3;

  private direction: google.maps.Polyline = null;

  private directionStart: google.maps.LatLng = null;

  private section: google.maps.Rectangle = null;

  private sectionCorner1: google.maps.LatLng = null;

  private markers: google.maps.Marker[] = [];

  private sectionMarkers: google.maps.Marker[] = [];

  private startMarker: google.maps.Marker = null;

  private lastMarker: google.maps.Marker = null;

  private mc: MarkerClusterer = null;

  private sectionCallback: SectionCallback = null;

  private sectionClickCallback: SectionCallback = null;

  private directionCallback: DirectionCallback = null;

  private cursorUnSelected = 'crosshair';

  private cursorSelected = '';

  private lines: Line[] = [];

  private activeLineIndex: number = null;

  private linesListener: google.maps.MapsEventListener[] = [];

  private loading: boolean;

  private activeTripId: string;

  constructor(private ts: TranslateService, private datePipe: DatePipe) {}

  private getSectionBounds(): google.maps.LatLngBounds {
    if (this.section === null) {
      return null;
    }
    return this.section.getBounds();
  }

  private updateSection(latlng: google.maps.LatLng, noCallback: boolean): void {
    if (!noCallback && this.getSectionBounds() !== null) {
      this.clearSection(noCallback);
      // return;
    }
    if (this.sectionCorner1 === null) {
      this.sectionCorner1 = latlng;
      this.map.setOptions({ draggableCursor: this.cursorUnSelected });
      this._addMarker(
        latlng,
        '',
        StandStillMarkerRenderer.gooIconSectionStart,
        10000,
        null,
        this.sectionMarkers
      );
    } else {
      this.map.setOptions({ draggableCursor: this.cursorSelected });
      this._removeMarkers(this.sectionMarkers);
      this.section = this.createSection(
        this.sectionCorner1,
        latlng,
        noCallback
      );
      if (!noCallback) {
        this.sectionUpdate();
      }
      this.section.addListener('click', this.sectionClick.bind(this));
      this.section.addListener('bounds_changed', this.sectionUpdate.bind(this));
    }
  }

  private updateDirection(
    latlng: google.maps.LatLng,
    noCallback: boolean
  ): void {
    if (!noCallback && this.directionStart !== null) {
      this.clearDirection(noCallback);
      // return;
    }
    if (this.directionStart === null) {
      this.directionStart = latlng;
      this.map.setOptions({ draggableCursor: this.cursorUnSelected });
      this._addMarker(
        latlng,
        '',
        StandStillMarkerRenderer.gooIconSectionStart,
        10000,
        null,
        this.sectionMarkers
      );
    } else {
      this.map.setOptions({ draggableCursor: this.cursorSelected });
      this._removeMarkers(this.sectionMarkers);
      this.direction = this.createDirection(
        this.directionStart,
        latlng,
        noCallback
      );
      this.direction.addListener('insert_at', this.directionUpdate.bind(this));
      this.direction.addListener('remove_at', this.directionUpdate.bind(this));
      this.direction.addListener('set_at', this.directionUpdate.bind(this));
      this.direction.addListener('dragend', this.directionUpdate.bind(this));
      this.direction.addListener('click', this.directionUpdate.bind(this));
      if (!noCallback) {
        this.directionUpdate();
      }
    }
  }

  private sectionUpdate(): void {
    if (this.sectionCallback) {
      const bounds = this.section.getBounds();
      const tsp = TripService.createTripSectionParameterByCoord(
        'map.updateSection',
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng(),
        bounds.getSouthWest().lat(),
        bounds.getSouthWest().lng()
      );
      this.sectionCallback(tsp);
    }
  }

  private sectionClick(): void {
    if (this.sectionClickCallback) {
      const bounds = this.section.getBounds();
      const tsp = TripService.createTripSectionParameterByCoord(
        'map.clickSection',
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng(),
        bounds.getSouthWest().lat(),
        bounds.getSouthWest().lng()
      );
      this.sectionClickCallback(tsp);
    }
  }

  private directionUpdate(): void {
    if (this.directionCallback) {
      const directionPath = this.direction.getPath();
      const tdp = TripService.createTripRoutesDirection(
        null,
        null,
        this.directionAlternatives,
        directionPath.getArray()[0].lat(),
        directionPath.getArray()[0].lng(),
        directionPath.getArray()[1].lat(),
        directionPath.getArray()[1].lng()
      );
      this.directionCallback(tdp);
    }
  }

  private createSection(
    corner1: google.maps.LatLng,
    corner2: google.maps.LatLng,
    noCallback: boolean
  ): google.maps.Rectangle {
    this.clearSection(noCallback);
    this.sectionCorner1 = corner1;
    const section: google.maps.Rectangle = new google.maps.Rectangle({
      bounds: StandStillMarkerRenderer.createBounds(corner1, corner2),
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      editable: true,
      draggable: true,
    });
    return section;
  }

  private createDirection(
    start: google.maps.LatLng,
    end: google.maps.LatLng,
    noCallback: boolean
  ): google.maps.Polyline {
    this.clearDirection(noCallback);
    this.directionStart = start;
    const direction: google.maps.Polyline = new google.maps.Polyline({
      path: [start, end],
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      map: this.map,
      editable: true,
      draggable: true,
    });
    return direction;
  }

  private clearSection(noCallback: boolean): void {
    if (this.section != null) {
      this.section.setMap(null);
      this.section = null;
      this.sectionCorner1 = null;
      this.sectionMarkers = this._removeMarkers(this.sectionMarkers);
      if (!noCallback && this.sectionCallback) {
        this.sectionCallback(null);
      }
    }
    this.map.setOptions({ draggableCursor: this.cursorUnSelected });
  }

  private clearDirection(noCallback: boolean): void {
    if (this.direction != null) {
      this.direction.setMap(null);
      this.direction = null;
      this.directionStart = null;
      this.sectionMarkers = this._removeMarkers(this.sectionMarkers);
      if (!noCallback && this.directionCallback) {
        this.directionCallback(null);
      }
    }
    this.map.setOptions({ draggableCursor: this.cursorUnSelected });
  }

  private _removeMarkers(markers: google.maps.Marker[]): google.maps.Marker[] {
    StandStillMarkerRenderer.removeMarkers(markers);
    return [];
  }

  private _addMarker(
    latLng: google.maps.LatLng,
    name: string,
    icon: google.maps.Icon,
    zindex: number,
    infoContent: string,
    markers: google.maps.Marker[],
    markerPopup?: MarkerPopup
  ): google.maps.Marker {
    const marker = StandStillMarkerRenderer.createMarker(
      latLng,
      name,
      icon,
      zindex,
      infoContent,
      markerPopup
    );
    StandStillMarkerRenderer.addMarker(this.map, marker);
    markers.push(marker);
    return marker;
  }

  private removeMarker(marker: google.maps.Marker): void {
    StandStillMarkerRenderer.removeMarker(marker);
  }

  private static determineColor(ece: number): string {
    if (ece < 1) {
      return '#00FF00'; // green
    }
    if (ece < 1.5) {
      return '#FFFF00'; // yellow
    }
    return '#FF0000'; // red
  }

  private static determineColorUnselected(ece: number): string {
    if (ece < 1) {
      // return '#BBC1BBFF'; // green
      return '#69AA69FF'; // green
    }
    if (ece < 1.5) {
      // return '#696967FF'; // yellow
      return '#A9A976FF'; // yellow
    }
    // return '#1E1E1EFF'; // red
    return '#865B5BFF'; // red
  }

  private isTripOk(trip: Trip): boolean {
    return (
      trip !== null &&
      trip.positions !== undefined &&
      trip.positions !== null &&
      trip.positions.length > 0
    );
  }

  private _getLine(index: number): Line {
    const i = !index ? 0 : index;
    if (!this.lines[i]) {
      this.lines.push({
        trip: null,
        polylines: [],
        polylinesUnselected: [],
      });
    }
    return this.lines[i];
  }

  private _showLine(index: number): void {
    this.activeLineIndex = index;
    this._removeClickEvent();
    for (let i = 0; i < this.getTripCount(); i += 1) {
      const l = this._getLine(i);
      l.polylines.forEach((pl) => {
        if (i === index) {
          pl.setMap(this.map);
          pl.setOptions({ zIndex: 1, strokeWeight: 6 });
        } else {
          pl.setMap(null);
        }
      });
      l.polylinesUnselected.forEach((pl) => {
        if (i === index) {
          pl.setMap(null);
        } else {
          pl.setMap(this.map);
          pl.setOptions({ zIndex: 0, strokeWeight: 4 });
          this._addClickEvent(pl, i);
        }
      });
    }
  }

  private _lineClickEvent(index: number): void {
    this._showLine(index);
  }

  private _addClickEvent(polyLine: google.maps.Polyline, index: number): void {
    this.linesListener.push(
      polyLine.addListener('click', () => {
        this._lineClickEvent(index);
      })
    );
  }

  private _removeClickEvent(): void {
    while (this.linesListener.length) {
      this.linesListener.pop().remove();
    }
  }

  private _addLine(
    positions: google.maps.LatLng[],
    color: string,
    colorUnselected: string,
    index: number
  ): void {
    const line = this._getLine(index);
    const pl = new google.maps.Polyline();
    pl.setPath(positions);
    pl.setOptions({ strokeColor: color, clickable: true });
    line.polylines.push(pl);
    const plg = new google.maps.Polyline();
    plg.setPath(positions);
    plg.setOptions({ strokeColor: colorUnselected, clickable: true });
    line.polylinesUnselected.push(plg);
  }

  private addDirection(index: number): void {
    const { trip } = this._getLine(index);
    let latlngs: google.maps.LatLng[] = [];
    let lastC = MapHelper.determineColor(trip.positions[0].percentageWork);
    let lastCUnselected = MapHelper.determineColorUnselected(
      trip.positions[0].percentageWork
    );
    for (let i = 0; i < trip.positions.length; i += 1) {
      const p = trip.positions[i];
      const c = MapHelper.determineColor(p.percentageWork);
      const uc = MapHelper.determineColorUnselected(p.percentageWork);
      if (lastC !== c) {
        this._addLine(latlngs, lastC, uc, index);
        latlngs = [latlngs[latlngs.length - 1]];
        lastC = c;
        lastCUnselected = uc;
      }
      latlngs.push(new google.maps.LatLng(p.latitude, p.longitude));
      if (p.fixFlag === FixFlag.CUT) {
        this._addLine(latlngs, lastC, lastCUnselected, index);
        latlngs = [];
      }
    }
    if (latlngs.length > 0) {
      this._addLine(latlngs, lastC, lastCUnselected, index);
    }
    this._showLine(0);
  }

  private updateStartMarker(index: number): void {
    const { trip } = this._getLine(index);
    const title = this.ts.instant('TRIP.STARTPOSITION');
    let infoContent = `<b>${title}</b>`;
    const p = trip.positions[0];
    const infoDate = this.datePipe.transform(
      p.dtime,
      this.ts.instant('DATEFORMAT')
    );
    if (this.getTripCount() === 1) {
      infoContent = `${infoContent} <p> ${infoDate} </p>`;
    }
    this.startMarker = this.replaceMarker(
      this.startMarker,
      new google.maps.LatLng(p.latitude, p.longitude),
      title,
      StandStillMarkerRenderer.gooIconStart,
      Number.MAX_VALUE,
      infoContent
    );
  }

  private updateLastMarker(index: number) {
    const { trip } = this._getLine(index);
    const title = this.ts.instant('TRIP.LASTPOSITION');
    let infoContent = `<b>${title}</b>`;
    const p = trip.positions[trip.positions.length - 1];
    const infoDate = this.datePipe.transform(
      p.dtime,
      this.ts.instant('DATEFORMAT')
    );
    if (this.getTripCount() === 1) {
      infoContent = `${infoContent} <p> ${infoDate} </p>`;
    }
    this.lastMarker = this.replaceMarker(
      this.lastMarker,
      new google.maps.LatLng(p.latitude, p.longitude),
      title,
      StandStillMarkerRenderer.gooIconEnd,
      Number.MAX_VALUE,
      infoContent
    );
  }

  private createStandStillMarkers(index: number): void {
    if (this.mc) {
      this.mc.clearMarkers();
    }
    const { trip } = this._getLine(index);
    const title = this.ts.instant('TRIP.STANDSTILL');
    const labelTime = this.ts.instant('TRIP.TIME');
    const labelDurance = this.ts.instant('TRIP.DURANCE');
    const tf = this.ts.instant('DATEFORMAT');
    for (let i = 0; i < trip.positions.length; i += 1) {
      const p = trip.positions[i];
      if (p.standStillTime > 0) {
        const standStillTime = secondsToDuration(p.standStillTime);
        const infoDate = this.datePipe.transform(p.dtime, tf);
        const infoContent = `<b>${title}</b><p> ${labelTime}: ${infoDate} </p><p> ${labelDurance}: ${standStillTime} </p>`;
        const gooPos = new google.maps.LatLng(p.latitude, p.longitude);
        const ssm = StandStillMarkerRenderer.createStandStillMarker(
          title,
          p.standStillTime,
          gooPos,
          p.standStillTime,
          infoContent
        );
        ssm.set('standStillTime', p.standStillTime);
        if (this.mc) {
          this.mc.addMarker(ssm);
        }
      }
    }
  }

  private resetCurrentBounds(): void {
    this.currentBounds = {
      latMax: 0,
      lngMax: 0,
      latMin: 1000,
      lngMin: 1000,
    };
  }

  private fitBoundsByTrips(): void {
    this.resetCurrentBounds();
    for (let i = 0; i < this.getTripCount(); i += 1) {
      const { trip } = this._getLine(i);
      trip.positions.map((p) => {
        const lat = p.latitude;
        const lng = p.longitude;
        if (this.currentBounds.latMax < lat) this.currentBounds.latMax = lat;
        if (this.currentBounds.lngMax < lng) this.currentBounds.lngMax = lng;
        if (this.currentBounds.latMin > lat) this.currentBounds.latMin = lat;
        if (this.currentBounds.lngMin > lng) this.currentBounds.lngMin = lng;
        return null;
      });
    }
    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(
        this.currentBounds.latMin,
        this.currentBounds.lngMin
      ),
      new google.maps.LatLng(
        this.currentBounds.latMax,
        this.currentBounds.lngMax
      )
    );
    this.fitMapBounds(bounds);
  }

  private isSectionSelected(): boolean {
    return this.section !== null;
  }

  private replaceMarker(
    rem: google.maps.Marker,
    latLng: google.maps.LatLng,
    name: string,
    icon: google.maps.Icon,
    zindex: number,
    infoContent: string,
    popup?: MarkerPopup
  ): google.maps.Marker {
    this.removeMarker(rem);
    return this.addMarker(latLng, name, icon, zindex, infoContent, popup);
  }

  // public

  public setSection(
    corner1Lat: number,
    corner1Lng: number,
    corner2Lat: number,
    corner2Lng: number
  ): void {
    this.sectionCorner1 = new google.maps.LatLng(corner1Lat, corner1Lng);
    this.updateSection(new google.maps.LatLng(corner2Lat, corner2Lng), true);
  }

  public getSelectedSectionBounds(): google.maps.LatLngBounds {
    if (this.isSectionSelected()) {
      return this.section.getBounds();
    }
    return null;
  }

  public setDirection(
    startLat: number,
    startLng: number,
    endLat: number,
    endLng: number
  ): void {
    this.directionStart = new google.maps.LatLng(startLat, startLng);
    this.updateDirection(new google.maps.LatLng(endLat, endLng), true);
  }

  public initMap(
    refMap: ElementRef,
    isSectionAllowed: boolean,
    enable?: boolean,
    isDirectionMap?: boolean
  ): google.maps.Map {
    if (refMap) {
      this.loading = true;
      if (isDirectionMap) {
        this.isDirectionMap = true;
      } else {
        this.isDirectionMap = false;
      }

      this.isSectionAllowed = isSectionAllowed;
      this.map = new google.maps.Map(refMap.nativeElement);
      if (!isSectionAllowed) {
        this.cursorUnSelected = this.cursorSelected;
      }
      const mco: MarkerClustererOptions = {
        map: this.map,
        renderer: new StandStillMarkerRenderer(this.ts),
      };
      this.mc = new MarkerClusterer(mco);
      const mapOptions: google.maps.MapOptions = {
        draggableCursor: this.cursorUnSelected,
      };
      this._reset(true);
      this.map.setOptions(mapOptions);
      this.map.fitBounds(this.bounds, this.boundsPadding);
      this.setEnabled(!isSectionAllowed || enable);
    }
    return this.map;
  }

  public setEnabled(enable: boolean): void {
    if (enable) {
      if (this.map) {
        this.map.addListener('click', (e) => {
          StandStillMarkerRenderer.hideInfo();
          if (this.isSectionAllowed) {
            // eslint-disable-next-line dot-notation
            this.updateSection(e['latLng'], false);
          } else if (this.isDirectionMap) {
            // eslint-disable-next-line dot-notation
            this.updateDirection(e['latLng'], false);
          }
        });
        this.map.setOptions({
          disableDefaultUI: null,
          clickableIcons: true,
          disableDoubleClickZoom: null,
          draggable: null,
          fullscreenControl: null,
        });
      }
    } else {
      if (this.mapListener) {
        this.mapListener.remove();
        this.mapListener = null;
      }
      if (this.map) {
        this.map.setOptions({
          disableDefaultUI: true,
          clickableIcons: false,
          disableDoubleClickZoom: true,
          draggable: false,
          fullscreenControl: false,
        });
      }
    }
  }

  public removeMarkers(): void {
    this.markers = this._removeMarkers(this.markers);
  }

  public addMarker(
    latLng: google.maps.LatLng,
    name: string,
    icon: google.maps.Icon,
    zindex: number,
    infoContent: string,
    markerPopup?: MarkerPopup
  ): google.maps.Marker {
    return this._addMarker(
      latLng,
      name,
      icon,
      zindex,
      infoContent,
      this.markers,
      markerPopup
    );
  }

  public fitMapBounds(bounds: google.maps.LatLngBounds): void {
    if (this.map) {
      this.map.fitBounds(bounds, this.boundsPadding);
    }
  }

  public registerSectionCallback(sectionCallback: SectionCallback): void {
    this.sectionCallback = sectionCallback;
  }

  public registerSectionClickCallback(
    sectionClickCallback: SectionCallback
  ): void {
    this.sectionClickCallback = sectionClickCallback;
  }

  public registerDirectionCallback(directionCallback: DirectionCallback): void {
    this.directionCallback = directionCallback;
  }

  private _reset(reset?: boolean): void {
    if (reset) {
      this.lines = [];
      this.activeLineIndex = null;
    }
  }

  public addTripToMap(trip: Trip, reset?: boolean): number {
    this._reset(reset);
    if (this.map) {
      if (this.isTripOk(trip)) {
        const index = this.getTripCount();
        this._getLine(index).trip = trip;
        this.fitBoundsByTrips();
        this.addDirection(index);
        this.createStandStillMarkers(index);
        this.updateStartMarker(index);
        this.updateLastMarker(index);
        if (reset) {
          this.loading = false;
        }
        if (this.activeTripId) {
          this.setActiveTripById(this.activeTripId);
        }
        return index;
      }
    }
    return -1;
  }

  public addTripResponsesToMap(tripResponses: TripResponse[]): void {
    this.loading = true;
    for (let i = 0; i < tripResponses.length; i += 1) {
      this.addTripToMap(tripResponseToTrip(tripResponses[i]));
    }
    this.loading = false;
  }

  public setActiveTripById(tripId: string): void {
    this.activeTripId = tripId;
    for (let i = 0; i < this.getTripCount(); i += 1) {
      if (this._getLine(i).trip.id === tripId) {
        this._showLine(i);
      }
    }
  }

  public getActiveTripId(): string {
    return this._getLine(this.activeLineIndex).trip.id;
  }

  public getTripCount(): number {
    return this.lines.length;
  }

  public getTrips(): Trip[] {
    const trips: Trip[] = [];
    this.lines.forEach((line) => {
      trips.push(line.trip);
    });
    return trips;
  }

  public getTrip(index: number): Trip {
    if (this.getTripCount() >= index + 1) {
      return this._getLine(index).trip;
    }
    return null;
  }

  public isBusy(): boolean {
    return this.loading;
  }
}
