import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from '@mat-datetimepicker/core';
import { MomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatInputModule } from '@angular/material/input';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { TripSpeedProfileComponent } from './trips-list/trip-analysis/trip-speed-profile/trip-speed-profile.component';
import { TripMultiInfoDistanceChartComponent } from './trips-list/trip-analysis/trip-multi-info-chart/trip-multi-info-distance-chart.component';
import { TripMultiInfoTimeChartComponent } from './trips-list/trip-analysis/trip-multi-info-chart/trip-multi-info-time-chart.component';
import { TripMapComponent } from './trips-list/trip-analysis/trip-map/trip-map.component';
import { TripAltitudeProfileComponent } from './trips-list/trip-analysis/trip-altitude-profile/trip-altitude-profile.component';
import { TripDetailsComponent } from './trips-list/trip-analysis/trip-details/trip-details.component';
import { TripCo2ProfileComponent } from './trips-list/trip-analysis/trip-co2-profile/trip-co2-profile.component';
import { TripWayProfileComponent } from './trips-list/trip-analysis/trip-way-profile/trip-way-profile.component';
import { TripAnalysisComponent } from './trips-list/trip-analysis/trip-analysis.component';
import { TripAnalysisDialogComponent } from './trips-list/trip-analysis-dialog/trip-analysis-dialog.component';
import { TripMultipleDetailsComponent } from './trips-list/trip-multiple-details/trip-multiple-details.component';
import { TripsComponent } from './trips.component';
import { TripsListComponent } from './trips-list/trips-list.component';
import { TripsMapComponent } from './trips-map/trips-map.component';
import { RecalculateDialogComponent } from './trips-list/trip-analysis/trip-details/recalculate-dialog/recalculate-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { TripsEvaluationComponent } from './trips-evaluation/trips-evaluation.component';
import { TripsToolsComponent } from './trips-tools/trips-tools.component';
import { CUSTOM_MOMENT_FORMATS } from '../utils/custom-date-formats';
import { OverlaySpinnerTripsActionComponent } from '../utilComponents/overlay-spinner-trips-action/overlay-spinner-trips-action.component';
import { TripRoutingComponent } from './trip-routing/trip-routing.component';
import { DirectionMapComponent } from './direction-map/direction-map.component';

@NgModule({
  declarations: [
    TripsComponent,
    TripSpeedProfileComponent,
    TripMultiInfoDistanceChartComponent,
    TripMultiInfoTimeChartComponent,
    TripMapComponent,
    TripAltitudeProfileComponent,
    TripDetailsComponent,
    TripCo2ProfileComponent,
    TripWayProfileComponent,
    TripAnalysisComponent,
    TripAnalysisDialogComponent,
    TripMultipleDetailsComponent,
    TripsListComponent,
    TripsMapComponent,
    RecalculateDialogComponent,
    TripsEvaluationComponent,
    TripsToolsComponent,
    OverlaySpinnerTripsActionComponent,
    TripRoutingComponent,
    DirectionMapComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgChartsModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MomentDatetimeModule,
    MatInputModule,
    BrowserAnimationsModule,
  ],
  providers: [
    provideAnimations(),
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
  ],
})
export class TripsModule {}
