import { AbstractControl } from '@angular/forms';
import { formatNumber } from '@angular/common';
import { Group, Trip, TripResponse, User } from 'lcmm-lib-js';
import { TranslateService } from '@ngx-translate/core';

// MJ/l
export enum FuelType {
  DIESEL = 35.712,
  GASOLINE = 31.32,
  ELECTRIC = 3.6,
}

export interface EnumKeyValue {
  key: string;
  value: number;
}

export const enumSelector = (enumType: unknown): EnumKeyValue[] => {
  return (
    Object.keys(enumType)
      // eslint-disable-next-line no-restricted-globals
      .filter((k) => isNaN(Number(k)))
      .map((key) => {
        const kv: EnumKeyValue = {
          value: enumType[key],
          key,
        };
        return kv;
      }) as EnumKeyValue[]
  );
};

export const getEnumKeyByValue = (enumType: unknown, value: number): string => {
  const kvs: EnumKeyValue[] = enumSelector(enumType);
  for (let index = 0; index < kvs.length; index += 1) {
    const k = kvs[index].key;
    const v = kvs[index].value;
    if (value === v) {
      return k;
    }
  }
  return null;
};

export const isFuelTypeValueElectric = (value: number): boolean => {
  const key = getEnumKeyByValue(FuelType, value);
  return key === FuelType[FuelType.ELECTRIC];
};

export const getFuelTypeNameByValue = (
  translateService: TranslateService,
  value: number
): string => {
  const key = getEnumKeyByValue(FuelType, value);
  if (key) {
    return translateService.instant(`VEHICLE.${key}`);
  }
  return 'MJ/l';
};

export const isEnumValue = (enumType: unknown, value: number): boolean => {
  const key = getEnumKeyByValue(enumType, value);
  return key !== null;
};

export const isEnumValueKnown = (
  enumType: unknown,
  control: AbstractControl,
  valueKey: string
): boolean => {
  try {
    let contr = control;
    const keys = valueKey.split('.');
    for (let i = 0; i < keys.length; i += 1) {
      contr = contr[keys[i]];
    }
    return isEnumValue(enumType, contr as unknown as number);
  } catch (error) {
    return false;
  }
};

export const getControlValue = (
  control: AbstractControl,
  valueKey: string
): unknown => {
  try {
    let contr = control;
    const keys = valueKey.split('.');
    for (let i = 0; i < keys.length; i += 1) {
      contr = contr[keys[i]];
    }
    return contr;
  } catch (error) {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUsersObject = (users: User[]): { [id: string]: User } => {
  const obj = {};
  users.forEach((element) => {
    obj[element.id] = element;
  });
  return obj;
};

export const sortString = <T>(property: keyof T) => {
  return (a: T, b: T): number => {
    if (String(a[property]).toUpperCase() < String(b[property]).toUpperCase())
      return -1;
    if (String(a[property]).toUpperCase() > String(b[property]).toUpperCase())
      return 1;
    return 0;
  };
};

export const determineAndSetGroupLevel = (group: Group): Group => {
  if (group && group.groupIdentifier) {
    // eslint-disable-next-line no-param-reassign
    group.level = group.groupIdentifier.split('.').length - 1;
  }
  return group;
};

export const flattenGroups = (groups: Group[]): Group[] => {
  const returnArray: Group[] = [];
  groups.forEach((element) => {
    returnArray.push(determineAndSetGroupLevel(element));
    if (element.subGroups) {
      returnArray.push(...flattenGroups(element.subGroups));
    }
  });
  return returnArray.sort(sortString('groupIdentifier'));
};

export const getLeveledGroupName = (group: Group): string => {
  let lgn = group.groupName;
  for (let i = 1; i < group.level; i += 1) {
    lgn = `- ${lgn}`;
  }
  return lgn;
};

export const isUserInGroup = (user: User, group: Group): boolean => {
  if (user.userGroupId === group.id) {
    return true;
  }
  if (group.subGroups) {
    return group.subGroups.some((subGroup: Group) => {
      return isUserInGroup(user, subGroup);
    });
  }
  return false;
};

export const secondsToDuration = (totalSeconds: number): string => {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor((totalSeconds / 60) % 60);
  const hours = Math.floor(totalSeconds / 3600);

  if (hours) {
    return `${hours}h${minutes}'`;
  }
  return `${minutes}'${seconds}''`;
};

export const secondsToDuration2 = (
  totalSeconds: number,
  locale: string
): string => {
  const s = formatNumber(Math.floor(totalSeconds % 60), locale, '2.');
  const m = formatNumber(Math.floor((totalSeconds / 60) % 60), locale, '2.');
  const h = formatNumber(Math.floor(totalSeconds / 3600), locale, '2.');
  return `${h}:${m}:${s}`;
};

export const durationtoSeconds = (duration: string): number => {
  const [hours, minutes, seconds] = duration.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
};

export const tripResponseToTrip = (tripResponse: TripResponse): Trip => {
  return {
    groupName: tripResponse.groupName,
    id: tripResponse.id,
    startTime: new Date(tripResponse.startTime).getTime(),
    userId: tripResponse.userId,
    vehicleId: tripResponse.vehicle.id,
    absoluteCalculation: tripResponse.absoluteCalculation,
    calculation: tripResponse.calculation,
    endTime: tripResponse.endTime
      ? new Date(tripResponse.endTime).getTime()
      : undefined,
    positions: tripResponse.positions,
    ranking: tripResponse.ranking,
  };
};

export const tripResponseByTrip = (trip: Trip): TripResponse => {
  const tr: TripResponse = trip as unknown as TripResponse;
  tr.startTime = new Date(tr.startTime);
  tr.endTime = new Date(tr.endTime);
  return tr;
};

export const updateTripByTripResponse = (
  trip: TripResponse,
  tripResponse: TripResponse
): void => {
  // eslint-disable-next-line no-param-reassign
  trip.absoluteCalculation = tripResponse.absoluteCalculation;
  // eslint-disable-next-line no-param-reassign
  trip.calculation = tripResponse.calculation;
  // eslint-disable-next-line no-param-reassign
  trip.ranking = tripResponse.ranking;
};
