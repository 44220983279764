import { Component, Input, OnInit } from '@angular/core';
import { Sensorstation } from 'sensorstation-lib-js';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstation-detail-map',
  templateUrl: './sensorstation-detail-map.component.html',
  styleUrls: ['./sensorstation-detail-map.component.scss'],
})
export class SensorstationDetailMapComponent implements OnInit {
  public url: string;

  @Input() public sensorstation: Sensorstation;

  constructor(public envService: EnvConfigurationService) {}

  ngOnInit(): void {
    this.url = `${this.envService.config.grafanaUrl}d-solo/ddws8zj7cd7nkd/one-sensorstation-map?orgId=1
    &var-group=${this.sensorstation.group}&var-deviceId=${this.sensorstation.deviceId}&panelId=4`;
  }
}
